
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"

interface Event {
  id: number,
  admin_ids: Array<number>,
}

interface Admin {
  id: number,
  selected: boolean,
}

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs('活动审核', ['活动管理']);
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      require_name: "",
      require_mobile: "",
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 100,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_PENDING_EVENTS, { ...formData.value, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentEvents;
          page.value.totalResult = store.getters.currentEventCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const data_types = ref([
      { name: "商家", id: 0 },
      { name: "企业", id: 1 },
    ]);

    const onReset = () => {
      formData.value = {
        require_name: "",
        require_mobile: "",
      };
    };

    const event = ref<Event>({
      id: 0,
      admin_ids: []
    })

    const onEdit = (id) => {
      router.push({ name: "pending_event_edit", params: { id: id }})
    }

    const current_user = store.getters.currentUser

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      data_types,
      onReset,
      event,
      onEdit,
      current_user,
    };
  },
});
