
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Plus } from "@element-plus/icons-vue";
import type { ElUpload, ElInput } from "element-plus";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";
import { Shop } from "@/store/modules/ShopModule"

// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
// import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
// import Image from '@ckeditor/ckeditor5-image/src/image';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import Font from '@ckeditor/ckeditor5-font/src/font'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
// import CKEditorWebpackPlugin from '@ckeditor/ckeditor5-dev-webpack-plugin'
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import JwtService from "@/core/services/JwtService"

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    Plus,
  },
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("活动新增", ["活动管理"]);
      } else {
        setCurrentPageBreadcrumbs("活动编辑", ["活动管理"]);
      }
    });

    const rules = ref({
      // business_district_id: [
      //   {
      //     required: true,
      //     message: "所属商圈不可为空",
      //     trigger: "change",
      //   },
      // ],
      // name: [
      //   {
      //     required: true,
      //     message: "伙伴名称不可为空",
      //     trigger: "change",
      //   },
      // ],
      // data_type: [
      //   {
      //     required: true,
      //     message: "伙伴类型不可为空",
      //     trigger: "change",
      //   },
      // ],
      // contact_name: [
      //   {
      //     required: true,
      //     message: "联系人不可为空",
      //     trigger: "change",
      //   },
      // ],
      // email: [
      //   {
      //     required: true,
      //     message: "Customer email is required",
      //     trigger: "change",
      //   },
      // ],
      // addressLine: [
      //   {
      //     required: true,
      //     message: "Address 1 is required",
      //     trigger: "change",
      //   },
      // ],
      // town: [
      //   {
      //     required: true,
      //     message: "Town is required",
      //     trigger: "change",
      //   },
      // ],
      // state: [
      //   {
      //     required: true,
      //     message: "State is required",
      //     trigger: "change",
      //   },
      // ],
      // postCode: [
      //   {
      //     required: true,
      //     message: "Post code is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const event = ref({
      name: "",
      contact_name: "",
      contact_mobile: "",
      sms_mobile: "",
      latitude_and_longitude: "",
      description: "<div></div>",
      address: "",
      open_from: new Date("1970-01-01T" + "00:00:00"),
      open_to: new Date("1970-01-01T" + "00:00:00"),
      cover_image: "",
      event_images_attributes: [] as Array<any>,
      status: "",
      tag_list: [] as Array<any>,
    });

    const shops = ref(Array<Shop>())

    store
      .dispatch(Actions.GET_SHOPS)
      .then(() => {
        shops.value = store.getters.currentShops
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const imageUrl = ref("");

    const onChange = (file, fileList) => {
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        imageUrl.value = e.target?.result?.toString() || "";
        event.value.cover_image = imageUrl.value;
      };
    };

    const submitFileList = [] as any;

    const imageListOnChange = (file, fileList) => {
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        submitFileList.push({
          image: e.target?.result,
        });
      };
    };

    const onCancel = () => {
      router.push({ name: "events" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            store
              .dispatch(Actions.CREATE_EVENT, event.value)
              .then(() => {
                router.push({ name: "events" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_EVENT, {
                id: route.params.id,
                values: event.value,
              })
              .then(() => {
                router.push({ name: "events" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };

    const onWaitingForApprove = (formEl: FormInstance | undefined) => {
      console.log('onWaitingForApprove 1')
      if (!formEl) return;

      console.log('onWaitingForApprove 2')

      formEl.validate((valid) => {
        if (valid) {
          console.log('onWaitingForApprove 3')

          const params = {
            event: {
              id: route.params.id,
              description: event.value.description,
            }
          }

          store
            .dispatch(Actions.SUBMIT_EVENT, params)
            .then(() => {
              router.push({ name: "events" });
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        } else {
          console.log("error submit!")
          return false
        }
      });
    };

    const onApprove = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          const params = {
            event: {
              id: route.params.id,
              description: event.value.description,
            }
          }

          store
            .dispatch(Actions.APPROVE_EVENT, params)
            .then(() => {
              router.push({ name: "events" });
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        } else {
          console.log("error submit!")
          return false
        }
      });
    };

    const uploadRef = ref<InstanceType<typeof ElUpload>>();

    if (!isNew) {
      store
        .dispatch(Actions.GET_EVENT, route.params.id)
        .then(() => {
          event.value = store.getters.currentEvent;
          // event.value.open_from = new Date(
          //   "1970-01-01T" + (store.getters.currentEvent.open_from || "00:00:00")
          // );
          // event.value.open_to = new Date(
          //   "1970-01-01T" + (store.getters.currentEvent.open_to || "00:00:00")
          // );
          imageUrl.value = event.value.cover_image.startsWith("http")
            ? event.value.cover_image
            : "";

          // event.value.event_images_attributes.forEach(function (i) {
          //   fileList.value.push({
          //     id: i.id,
          //     url: i.image.url,
          //   });
          // });
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const disabled = ref(false);

    const handleRemove = (file) => {
      if (file.id) {
        submitFileList.push({
          id: file.id,
          _destroy: 1,
        });
      }

      let resultArr = fileList.value.filter((i) => {
        return i.id === file.id && i.url === file.url;
      });

      fileList.value.splice(fileList.value.indexOf(resultArr[0]), 1);
    };

    const fileList = ref([] as any);

    const business_districts = ref([]);

    const data_types = ref([
      { name: "商家", id: 0 },
      { name: "企业", id: 1 },
    ]);

    store
      .dispatch(Actions.GET_BUSINESS_DISTRICTS)
      .then(() => {
        business_districts.value = store.getters.currentBusinessDistricts;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const inputValue = ref('')
    const inputVisible = ref(false)
    const InputRef = ref<InstanceType<typeof ElInput>>()

    const handleClose = (tag: string) => {
      event.value.tag_list.splice(event.value.tag_list.indexOf(tag), 1)
    }

    const showInput = () => {
      inputVisible.value = true
      nextTick(() => {
        InputRef.value!.input!.focus()
      })
    }

    const handleInputConfirm = () => {
      if (inputValue.value) {
        event.value.tag_list.push(inputValue.value)
      }
      inputVisible.value = false
      inputValue.value = ''
    }

    const editor = ClassicEditor
    const editorConfig = {
        // fontFamily: {
        //   options: [
        //     "宋体",
        //     "仿宋",
        //     "微软雅黑",
        //     "黑体",
        //     "仿宋_GB2312",
        //     "楷体",
        //     "Arial"
        //   ]
        // },
        plugins: [
            EssentialsPlugin,
            BoldPlugin,
            ItalicPlugin,
            LinkPlugin,
            ParagraphPlugin,
            Image, 
            ImageToolbar, 
            ImageCaption, 
            ImageStyle, 
            ImageResize, 
            LinkImage,
            ImageUploadPlugin,
            // Base64UploadAdapter,
            SimpleUploadAdapter,
            Font,
            Alignment,
            // new CKEditorWebpackPlugin( {
            //     // The main language that will be built into the main bundle.
            //     language: 'zh-cn',

            //     // Additional languages that will be emitted to the `outputDirectory`.
            //     // This option can be set to an array of language codes or `'all'` to build all found languages.
            //     // The bundle is optimized for one language when this option is omitted.
            //     additionalLanguages: 'all',

            //     // For more advanced options see https://github.com/ckeditor/ckeditor5-dev/tree/master/packages/ckeditor5-dev-webpack-plugin.
            // }),
            // SimpleUploadAdapter,
        ],
        toolbar: {
            items: [
                'fontSize', 
                // 'fontFamily', 
                'fontColor', 
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'link',
                '|',
                'alignment',
                'undo',
                'redo',
                'imageStyle:block',
                'imageStyle:side',
                '|',
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'linkImage',
                'imageUpload',
            ]
        },
        simpleUpload: {
            // The URL that the images are uploaded to.
            // uploadUrl: 'http://localhost:3000/api/admin/system_settings/image_upload',
            // uploadUrl: 'https://api-cycg.omo365.com/api/admin/system_settings/image_upload',
            uploadUrl: 'https://api.cgjun.net/api/admin/system_settings/image_upload',
            
            
            // Enable the XMLHttpRequest.withCredentials property.
            // withCredentials: true,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                // 'X-CSRF-TOKEN': 'CSRF-Token',
                Authorization: `Bearer ${JwtService.getToken()}`
            }
        },
        // toolbar: {
        //   items: [
        //       'heading',
        //       '|',
        //       'alignment',                                                 // <--- ADDED
        //       'bold',
        //       'italic',
        //       'link',
        //       'bulletedList',
        //       'numberedList',
        //       'uploadImage',
        //       'blockQuote',
        //       'undo',
        //       'redo'
        //   ]
        // },
        // image: {
        //   toolbar: [
        //     'imageStyle:inline',
        //     'imageStyle:block',
        //     'imageStyle:side',
        //     '|',
        //     'toggleImageCaption',
        //     'imageTextAlternative'
        //   ]
        // },
        // // This value must be kept in sync with the language defined in webpack.config.js.
        // language: 'cn',
        // plugins: [ 
        //   // Base64UploadAdapter,
        //   Essentials,
        //   UploadAdapter,
        //   Autoformat,
        //   Bold,
        //   Italic,
        //   BlockQuote,
        //   EasyImage,
        //   Heading,
        //   Image,
        //   ImageCaption,
        //   ImageStyle,
        //   ImageToolbar,
        //   ImageUpload,
        //   Link,
        //   List,
        //   Paragraph,
        //   // Alignment,
        // ],
        // toolbar: [],
        // extraPlugins: [
        //   Base64UploadAdapter,
        // ],

        // toolbar: {
        //     items: [
        //         'bold',
        //         'italic',
        //         'link',
        //         'undo',
        //         'redo'
        //     ]
        // }

        // plugins: [ Base64UploadAdapter ],
        // // The configuration of the editor.
        // simpleUpload: {
        //   // The URL the images are uploaded to.
        //   uploadUrl: 'http://example.com',

        //   // Headers sent along with the XMLHttpRequest to the upload server.
        //   headers: {
        //   }
        // }
    }

    const current_user = store.getters.currentUser

    return {
      onSubmit,
      event,
      imageUrl,
      rules,
      uploadRef,
      onChange,
      handleRemove,
      dialogImageUrl,
      dialogVisible,
      disabled,
      fileList,
      imageListOnChange,
      onCancel,
      formRef,
      business_districts,
      data_types,
      shops,
      inputValue,
      inputVisible,
      InputRef,
      handleClose,
      showInput,
      handleInputConfirm,
      editor,
      editorConfig,
      current_user,
      onWaitingForApprove,
      onApprove,
    };
  },
});
