
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("商圈新增", ["商圈列表"]);
      } else {
        setCurrentPageBreadcrumbs("商圈编辑", ["商圈列表"]);
      }
    });

    const rules = ref({
      no: [
        {
          required: true,
          message: "商圈代号不可为空",
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: "商圈名称不可为空",
          trigger: "change",
        },
      ]
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const business_district = ref({
      name: "",
    });

    const onCancel = () => {
      router.push({ name: "business_districts" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            store
              .dispatch(
                Actions.CREATE_BUSINESS_DISTRICT,
                business_district.value
              )
              .then(() => {
                router.push({ name: "business_districts" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_BUSINESS_DISTRICT, {
                id: route.params.id,
                values: business_district.value,
              })
              .then(() => {
                router.push({ name: "business_districts" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          return false;
        }
      });
    };

    if (!isNew) {
      store
        .dispatch(Actions.GET_BUSINESS_DISTRICT, route.params.id)
        .then(() => {
          business_district.value = store.getters.currentBusinessDistrict;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    return {
      onSubmit,
      business_district,
      rules,
      onCancel,
      formRef,
    };
  },
});
