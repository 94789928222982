
import { defineComponent, ref, toRef } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  name: "shop-select-modal",
  props: ["coupon_item", "shops"],
  components: {},
  setup(props) {
    const formRef = ref<FormInstance>();
    const store = useStore();
    const shopSelectModalRef = ref<null | HTMLElement>(null);

    // const tmp_shops = toRef(props, "shops");

    const onSubmit = (formEl: FormInstance | undefined) => {
      let selected_shop_ids = Array<number>()

      props.shops.forEach(element => {
        if (element.selected) {
          selected_shop_ids.push(element.id)
        }
      })

      let params = {
        coupon_item: {
          id: props.coupon_item.id,
          shop_ids: selected_shop_ids,
        },
      };

      store
        .dispatch(Actions.UPDATE_COUPON_ITEM_SHOPS, params)
        .then(() => {
          hideModal(shopSelectModalRef.value)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    };

    const onCancel = () => {
      hideModal(shopSelectModalRef.value);
    };

    return {
      onSubmit,
      onCancel,
      formRef,
      shopSelectModalRef,
      // shops,
    };
  },
});
