import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface BusinessDistrict {
  name: string;
  // surname: string;
  // email: string;
  // password: string;
  // api_token: string;
}

export interface BusinessDistrictInfo {
  business_districts: Array<BusinessDistrict>;
  business_district: BusinessDistrict;
}

@Module
export default class BusinessDistrictModule
  extends VuexModule
  implements BusinessDistrictInfo
{
  business_districts = [] as Array<BusinessDistrict>;
  business_district_count = 0;
  business_district = {} as BusinessDistrict;

  get currentBusinessDistrict(): BusinessDistrict {
    return this.business_district;
  }

  get currentBusinessDistricts(): Array<BusinessDistrict> {
    return this.business_districts;
  }

  get currentBusinessDistrictCount() {
    return this.business_district_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_BUSINESS_DISTRICT](business_district) {
    this.business_district = business_district;
  }

  @Mutation
  [Mutations.SET_BUSINESS_DISTRICTS](business_districts) {
    this.business_districts = business_districts;
  }

  @Mutation
  [Mutations.SET_BUSINESS_DISTRICT_COUNT](business_district_count) {
    this.business_district_count = business_district_count;
  }

  @Action
  [Actions.GET_BUSINESS_DISTRICTS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/business_districts", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BUSINESS_DISTRICTS,
            data["business_districts"]
          );
          this.context.commit(
            Mutations.SET_BUSINESS_DISTRICT_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BUSINESS_DISTRICT](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/business_districts/${id}`)
        .then(({ data }) => {
          console.log(data["business_district"]);
          this.context.commit(
            Mutations.SET_BUSINESS_DISTRICT,
            data["business_district"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_BUSINESS_DISTRICT](credentials) {
    ApiService.setHeader();

    const params = {
      business_district: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/business_districts`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BUSINESS_DISTRICT,
            data["business_district"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_BUSINESS_DISTRICT](credentials) {
    ApiService.setHeader();

    const params = {
      business_district: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/business_districts/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BUSINESS_DISTRICT,
            data["business_district"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
