import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Event {
  name: string,
  // surname: string;
  // email: string;
  // password: string;
  // api_token: string;
}

export interface EventInvitation {
  id: number,
  name: string,
  mobile: string,
  code: string,
  status: string,
}

export interface EventRegistration {
  id: string,
  name: string,
  mobile: string,
  checked: boolean,
}

export interface EventInfo {
  events: Array<Event>;
  event: Event;
}

@Module
export default class EventModule extends VuexModule implements EventInfo {
  events = [] as Array<Event>;
  event_count = 0;
  event = {} as Event;
  event_invitations = [] as Array<EventInvitation>
  event_invitation_count = 0
  event_registrations = [] as Array<EventRegistration>
  event_registration_count = 0

  get currentEvent(): Event {
    return this.event;
  }

  get currentEvents(): Array<Event> {
    return this.events;
  }

  get currentEventCount() {
    return this.event_count;
  }

  get currentEventInvitations() {
    return this.event_invitations
  }

  get currentEventInvitationCount() {
    return this.event_invitation_count
  }
  
  get currentEventRegistrations() {
    return this.event_registrations
  }
  
  get currentEventRegistrationCount() {
    return this.event_registration_count
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_EVENT](event) {
    this.event = event;
  }

  @Mutation
  [Mutations.SET_EVENTS](events) {
    this.events = events;
  }

  @Mutation
  [Mutations.SET_EVENT_COUNT](event_count) {
    this.event_count = event_count;
  }

  @Mutation
  [Mutations.SET_EVENT_INVITATION_COUNT](event_invitation_count) {
    this.event_invitation_count = event_invitation_count;
  }

  @Mutation
  [Mutations.SET_EVENT_INVITATIONS](event_invitations) {
    this.event_invitations = event_invitations
  }

  @Mutation
  [Mutations.SET_EVENT_REGISTRATIONS](event_registrations) {
    this.event_registrations = event_registrations
  }

  @Mutation
  [Mutations.SET_EVENT_REGISTRATION_COUNT](event_registration_count) {
    this.event_registration_count = event_registration_count;
  }

  @Action
  [Actions.GET_EVENTS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/events", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENTS, data["events"]);
          this.context.commit(Mutations.SET_EVENT_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EVENT](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/events/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_EVENT](credentials) {
    ApiService.setHeader();

    const params = {
      event: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/events`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EVENT](credentials) {
    ApiService.setHeader();

    const params = {
      event: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EVENT_ADMINS](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${params.event.id}/update_admins`, params)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        });
    })
  }


  @Action
  [Actions.UPDATE_EVENT_GIFT_COUPONS](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${params.event.id}/update_gift_coupons`, params)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        });
    })
  }

  @Action
  [Actions.SUBMIT_EVENT](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${params.event.id}/submit`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.SUSPEND_EVENT](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${params.event.id}/suspend`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.APPROVE_EVENT](params) {
    ApiService.setHeader();

    console.log(params)

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${params.event.id}/approve`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EVENT_INVITATIONS](params) {
    ApiService.setHeader()

    let queryString = "?";

    if (params.query) {
      Object.keys(params.query).forEach((i) => {
        queryString += "&" + i + "=" + params.query[i]
      });
    }

    return new Promise<void>((resolve, reject) => {
      console.log(params)
      ApiService.get(`admin/events/${params.event.id}/invitations`, queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT_INVITATIONS, data["event_invitations"]);
          this.context.commit(Mutations.SET_EVENT_INVITATION_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EVENT_REGISTRATIONS](params) {
    console.log('GET_EVENT_REGISTRATIONS')
    ApiService.setHeader()

    let queryString = "?";

    if (params.query) {
      Object.keys(params.query).forEach((i) => {
        queryString += "&" + i + "=" + params.query[i]
      });
    }

    return new Promise<void>((resolve, reject) => {
      console.log(params)
      ApiService.get(`admin/events/${params.event.id}/registrations`, queryString)
        .then(({ data }) => {
          console.log(data)
          console.log(data["event_registrations"])
          this.context.commit(Mutations.SET_EVENT_REGISTRATIONS, data["event_registrations"]);
          this.context.commit(Mutations.SET_EVENT_REGISTRATION_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EVENT_INVITATIONS](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${params.event.id}/update_invitations`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT_INVITATIONS, data["event"]["event_invitations"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PENDING_EVENTS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/events/pending_events_index", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENTS, data["events"]);
          this.context.commit(Mutations.SET_EVENT_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REQUIRE_EVENT_APPROVE](params) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${params.event.id}/require_approve`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REQUIRE_EVENT_DENY](params) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${params.event.id}/require_deny`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
