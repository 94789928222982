import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface BudgetYear {
  name: string;
  // surname: string;
  // email: string;
  // password: string;
  // api_token: string;
}

export interface BudgetQuarter {
  id: string;
  budget_year_id: string;
  quarter: string;
  normal_amount: number;
  normal_amount_used: number;
  add_amount: number;
  add_amount_used: number;
  total_amount: number;
  total_amount_used: number;
  total_amount_left: number;
  left_amount_status: number;
  status: number;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
}

export interface BudgetYearInfo {
  budget_years: Array<BudgetYear>;
  budget_year: BudgetYear;
  budget_quarters: Array<BudgetQuarter>;
  budget_quarter: BudgetQuarter;
}

export interface BudgetYearOption {
  key: string;
  value: string;
}

@Module
export default class BudgetYearModule
  extends VuexModule
  implements BudgetYearInfo
{
  budget_years = [] as Array<BudgetYear>;
  budget_year_count = 0;
  budget_year = {} as BudgetYear;
  budget_quarters = [] as Array<BudgetQuarter>;
  budget_quarter = {} as BudgetQuarter;
  budget_year_options = [] as Array<BudgetYearOption>;
  shift_by_budget_quarters = [] as Array<BudgetQuarter>;

  get currentBudgetYear(): BudgetYear {
    return this.budget_year;
  }

  get currentBudgetYears(): Array<BudgetYear> {
    return this.budget_years;
  }

  get currentBudgetYearOptions(): Array<BudgetYearOption> {
    return this.budget_year_options;
  }

  get currentBudgetYearCount() {
    return this.budget_year_count;
  }

  get currentBudgetQuarter() {
    return this.budget_quarter;
  }

  get currentBudgetQuarters() {
    return this.budget_quarters;
  }

  get currentShiftByBudgetQuarters() {
    return this.shift_by_budget_quarters;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_BUDGET_YEAR](budget_year) {
    this.budget_year = budget_year;
  }

  @Mutation
  [Mutations.SET_BUDGET_YEARS](budget_years) {
    this.budget_years = budget_years;
  }

  @Mutation
  [Mutations.SET_BUDGET_YEAR_OPTIONS](budget_year_options) {
    this.budget_year_options = budget_year_options;
  }

  @Action
  [Actions.GET_BUDGET_YEARS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/budget_years", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUDGET_YEARS, data["budget_years"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.SHIFT_BUDGET](params) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `admin/budget_quarters/${params.budget_quarter.id}/borrow`,
        params
      )
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_BUDGET_YEAR, data["budget_year"])
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BUDGET_YEAR_OPTIONS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/budget_years/get_budget_year_options", queryString)
        .then(({ data }) => {
          console.log(data);
          this.context.commit(
            Mutations.SET_BUDGET_YEAR_OPTIONS,
            data["budget_year_options"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Mutation
  [Mutations.SET_BUDGET_QUARTER](budget_quarter) {
    this.budget_quarter = budget_quarter;
  }

  @Mutation
  [Mutations.SET_SHIFT_BY_BUDGET_QUARTERS](shift_by_budget_quarters) {
    this.shift_by_budget_quarters = shift_by_budget_quarters;
  }

  @Mutation
  [Mutations.SET_BUDGET_QUARTERS](budget_quarters) {
    this.budget_quarters = budget_quarters;
  }

  @Action
  [Actions.GET_BUDGET_QUARTERS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/budget_quarters", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BUDGET_QUARTERS,
            data["budget_quarters"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_BUDGET](params) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `admin/budget_quarters/${params.budget_quarter.id}/add`,
        params
      )
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_BUDGET_YEAR, data["budget_year"])
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BUDGET_QUARTER](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/budget_quarters/${id}`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BUDGET_QUARTER,
            data["budget_quarter"]
          );
          this.context.commit(
            Mutations.SET_SHIFT_BY_BUDGET_QUARTERS,
            data["shift_by_budget_quarters"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BUDGET_QUARTER_BY_PARAMS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params.budget_quarter).forEach((i) => {
        queryString += "&" + i + "=" + params.budget_quarter[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/budget_quarters/fetch_one", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BUDGET_QUARTER,
            data["budget_quarter"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject()
        })
    })
  }

  @Action
  [Actions.GET_BUDGET_YEAR](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/budget_years/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUDGET_YEAR, data["budget_year"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_BUDGET_YEAR](credentials) {
    ApiService.setHeader();

    const params = {
      budget_year: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/budget_years`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUDGET_YEAR, data["budget_year"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_BUDGET_YEAR](credentials) {
    ApiService.setHeader();

    const params = {
      budget_year: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/budget_years/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUDGET_YEAR, data["budget_year"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
