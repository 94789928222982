
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("管理員新增", ["系统管理", "管理员列表"]);
      } else {
        setCurrentPageBreadcrumbs("管理员编辑", ["系统管理", "管理员列表"]);
      }
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const admin = ref({
      name: "",
      mobile: "",
      email: "",
      password: "",
      password_confirmation: "",
    });

    const isNew = !route.params.id;

    const rules = ref({});

    if (isNew) {
      rules.value = {
        name: [
          {
            required: true,
            message: "管理员名称不可为空",
            trigger: "change",
          },
        ],
        mobile: [
          {
            required: true,
            message: "管理员手机号码不可为空",
            trigger: "change",
          },
        ],
        role: [
          {
            required: true,
            message: "管理员角色不可为空",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不可为空",
            trigger: "change",
          },
          {
            min: 6,
            message: "密码长度最少6位",
          },
        ],
        password_confirmation: [
          {
            required: true,
            message: "确认密码不可为空",
            trigger: "change",
          },
          {
            min: 6,
            message: "确认密码长度最少6位",
          },
        ],
      };
    } else {
      rules.value = {
        name: [
          {
            required: true,
            message: "管理员名称不可为空",
            trigger: "change",
          },
        ],
        mobile: [
          {
            required: true,
            message: "管理员手机号码不可为空",
            trigger: "change",
          },
        ],
        role: [
          {
            required: true,
            message: "管理员角色不可为空",
            trigger: "change",
          },
        ],
        password: [
          {
            min: 6,
            message: "密码长度最少6位",
          },
        ],
        password_confirmation: [
          {
            min: 6,
            message: "确认密码长度最少6位",
          },
        ],
      };
    }

    const onCancel = () => {
      router.push({ name: "admins" });
    };

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            console.log(admin.value)
            store
              .dispatch(Actions.CREATE_ADMIN, admin.value)
              .then(() => {
                router.push({ name: "admins" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            console.log(admin.value)

            store
              .dispatch(Actions.UPDATE_ADMIN, {
                id: route.params.id,
                values: admin.value,
              })
              .then(() => {
                router.push({ name: "admins" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          return false;
        }
      });
    };

    if (!isNew) {
      store
        .dispatch(Actions.GET_ADMIN, route.params.id)
        .then(() => {
          admin.value = store.getters.currentAdmin;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const roles = ref([
      {
        label: "管理者", value: 'admin'
      },
      {
        label: "活动编辑", value: 'editor'
      },
    ])

    return {
      onSubmit,
      admin,
      rules,
      onCancel,
      formRef,
      roles,
    };
  },
});
