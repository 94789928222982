
import { defineComponent, ref, toRef } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

interface BudgetYear {
  business_district_id: string;
  year: string;
  total_amount: number;
}

export default defineComponent({
  name: "new-budget-modal",
  props: ["shift_by_budget_quarters", "budget_quarter", "query"],
  components: {},
  setup(props) {
    const formRef = ref<FormInstance>();
    const store = useStore();
    const budgetBorrowModalRef = ref<null | HTMLElement>(null);
    const budget_quarter = toRef(props, "budget_quarter");

    const budget_year = ref<BudgetYear>({
      business_district_id: "",
      year: "",
      total_amount: 0,
    });

    const business_districts = ref([]);

    store
      .dispatch(Actions.GET_BUSINESS_DISTRICTS)
      .then(() => {
        business_districts.value = store.getters.currentBusinessDistricts;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const onSubmit = (formEl: FormInstance | undefined) => {
      let params = {
        budget_quarter: {
          id: props.budget_quarter.id,
          shift_by_budget_quarters: props.shift_by_budget_quarters,
        },
      };

      store
        .dispatch(Actions.SHIFT_BUDGET, params)
        .then(() => {
          props.query();
          hideModal(budgetBorrowModalRef.value);
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    const onCancel = () => {
      hideModal(budgetBorrowModalRef.value);
    };

    const shift_by_budget_quarter = ref({});

    // const shift_by_budget_quarter = store.getters.currentQuarter

    const onChange = () => {
      budget_quarter.value.total_shift_normal_amount = 0;
      budget_quarter.value.total_shift_add_amount = 0;

      props.shift_by_budget_quarters.forEach((element) => {
        budget_quarter.value.total_shift_normal_amount +=
          element.shift_normal_amount;
      });

      props.shift_by_budget_quarters.forEach((element) => {
        budget_quarter.value.total_shift_add_amount += element.shift_add_amount;
      });
    };

    return {
      budgetBorrowModalRef,
      business_districts,
      budget_year,
      onSubmit,
      formRef,
      shift_by_budget_quarter,
      onCancel,
      onChange,
      // shift_by_budget_quarters,
      // shift_by_budget_quarter,
    };
  },
});
