
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AdminSelectModal from "@/components/modals/forms/AdminSelectModal.vue"
import GiftCouponSelectModal from "@/components/modals/forms/GiftCouponSelectModal.vue"
import { GiftCoupon } from "@/store/modules/CouponModule"

interface Event {
  id: number,
  admin_ids: Array<number>,
  gift_coupon_ids: Array<number>,
}

interface Admin {
  id: number,
  selected: boolean,
}

export default defineComponent({
  components: {
    AdminSelectModal,
    GiftCouponSelectModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("活动列表", ['活动管理']);
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      no: "",
      name: "",
      status: ""
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 100,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_EVENTS, { ...formData.value, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentEvents;
          page.value.totalResult = store.getters.currentEventCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const newEvent = () => {
      router.push({ name: "event_new" });
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const business_districts = ref([
      // {name: '1', id: '1'},
      // {name: '2', id: '2'},
    ]);

    const data_types = ref([
      { name: "商家", id: 0 },
      { name: "企业", id: 1 },
    ]);

    store
      .dispatch(Actions.GET_BUSINESS_DISTRICTS)
      .then(() => {
        business_districts.value = store.getters.currentBusinessDistricts;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const onReset = () => {
      formData.value = {
        no: "",
        name: "",
        status: ""
      };
    };

    const event = ref<Event>({
      id: 0,
      admin_ids: [],
      gift_coupon_ids: [],
    })

    const admins = ref(Array<Admin>())

    const onAdminSelect = (id) => {
      store
        .dispatch(Actions.GET_EVENT, id)
        .then(() => {
          event.value = store.getters.currentEvent

          store
            .dispatch(Actions.GET_ADMINS)
            .then(() => {
              admins.value = store.getters.currentAdmins
              admins.value.forEach(element => {
                element.selected = event.value.admin_ids.includes(element.id)
              })
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const gift_coupons = ref(Array<GiftCoupon>())

    const onGiftCouponSelect = (id) => {
      store
        .dispatch(Actions.GET_EVENT, id)
        .then(() => {
          event.value = store.getters.currentEvent

          store
            .dispatch(Actions.GET_GIFT_COUPONS)
            .then(() => {
              gift_coupons.value = store.getters.currentGiftCoupons
              gift_coupons.value.forEach(element => {
                element.selected = event.value.gift_coupon_ids.includes(element.id)
              })
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const onInvitationSelect = (id) => {
      router.push({ name: "event_invitation", params: { id: id }})
    }

    const onRegistration = (id) => {
      router.push({ name: "event_registration", params: { id: id }})
    }

    const onEdit = (id) => {
      router.push({ name: "event_edit", params: { id: id }})
    }

    const onSuspend = (id) => {
      const params = {
        event: {
          id: id
        }
      }

      store
        .dispatch(Actions.SUSPEND_EVENT, params)
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const onDetail = (id) => {
      router.push({ name: "event_edit", params: { id: id }})
    }

    const current_user = store.getters.currentUser

    return {
      tableData,
      formData,
      submitEvent,
      newEvent,
      page,
      onPageChange,
      data_types,
      business_districts,
      onReset,
      event,
      admins,
      gift_coupons,
      onAdminSelect,
      onGiftCouponSelect,
      onEdit,
      onSuspend,
      current_user,
      onInvitationSelect,
      onRegistration,
      onDetail
    };
  },
});
