import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Shop {
  id: number,
  name: string,
  // surname: string;
  // email: string;
  // password: string;
  // api_token: string;
}

export interface ShopLogin {
  mobile: string;
  password: string;
  password_confirmation: string;
}

export interface ShopInfo {
  shops: Array<Shop>,
  shop: Shop,
}

@Module
export default class ShopModule extends VuexModule implements ShopInfo {
  shops = [] as Array<Shop>
  shop_count = 0
  shop = {} as Shop
  shop_login = {} as ShopLogin

  get currentShop(): Shop {
    return this.shop
  }

  get currentShops(): Array<Shop> {
    return this.shops
  }

  get currentShopCount() {
    return this.shop_count
  }

  get currentShopLogin() {
    return this.shop_login
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_SHOP](shop) {
    this.shop = shop;
  }

  @Mutation
  [Mutations.SET_SHOPS](shops) {
    this.shops = shops;
  }

  @Mutation
  [Mutations.SET_SHOP_COUNT](shop_count) {
    this.shop_count = shop_count;
  }

  @Mutation
  [Mutations.SET_SHOP_LOGIN](shop_login) {
    this.shop_login = shop_login
  }

  @Action
  [Actions.GET_SHOPS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/shops", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SHOPS, data["shops"]);
          this.context.commit(Mutations.SET_SHOP_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SHOP](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/shops/${id}`)
        .then(({ data }) => {
          console.log(data["shop"]);
          this.context.commit(Mutations.SET_SHOP, data["shop"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_SHOP](credentials) {
    ApiService.setHeader();

    const params = {
      shop: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/shops`, params)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_SHOP, data["shop"]);
          // resolve();

          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          this.context.commit(
            Mutations.SET_SHOP,
            data["shop"]
          )

          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_SHOP](credentials) {
    ApiService.setHeader();

    const params = {
      shop: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/shops/${credentials.id}`, params)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_SHOP, data["shop"]);
          // resolve();

          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          this.context.commit(
            Mutations.SET_SHOP,
            data["shop"]
          )

          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SHOP_LOGIN](params) {
    ApiService.setHeader();

    console.log("GET_SHOP_LOGIN")

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/shops/${params.shop.id}/login_edit`)
        .then(({ data }) => {
          console.log("-----")
          console.log(data)
          console.log("-----")
          this.context.commit(Mutations.SET_SHOP_LOGIN, data["shop_login"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_SHOP_LOGIN](params) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/shops/${params.shop.id}/login_update`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SHOP_LOGIN, data["shop_login"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

}
