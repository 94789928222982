
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter()
    
    // router.beforeEach((to, from, next) => {
    //     // ...

    //     console.log(to)
    //     console.log(to.meta.roles)
    //     console.log((to.meta.roles as Array<string>).includes("admin"))
    //     console.log(store.getters.currentUser.role)

    //     if (to.meta.roles && (to.meta.roles as Array<string>).includes(store.getters.currentUser.role)) {
    //       next()
    //     } else {
    //       next({ path: "/404" })
    //     }
    // })

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
