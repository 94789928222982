
import { defineComponent, ref, onMounted, toRef } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"

export default defineComponent({
  props: [
    "budget_quarter",
    "refresh_coupon_deliver_events",
    "formData",
    "query"
  ],
  setup(props) {
    const store = useStore()
    const tableData = ref([])
    const budget_quarter = toRef(props, "budget_quarter")
    const tmpFormData = toRef(props, "formData")

    const submitEvent = () => {
      props.query(true)
    }

    const business_districts = ref([])

    store
      .dispatch(Actions.GET_BUSINESS_DISTRICTS)
      .then(() => {
        business_districts.value = store.getters.currentBusinessDistricts;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })
    
    const budget_years = ref([])

    store
      .dispatch(Actions.GET_BUDGET_YEAR_OPTIONS)
      .then(() => {
        budget_years.value = store.getters.currentBudgetYearOptions
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const budget_quarters = ref([
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
      {
        key: 3,
        value: 3,
      },
      {
        key: 4,
        value: 4,
      },
    ])

    const onCancel = () => {
      tmpFormData.value = {
        business_district_id: '',
        budget_year: '',
        budget_quarter: '',
      }
    }

    const onSelectBusinessDistrict = (id) => {
      queryBudgetYears(id)
    }

    const queryBudgetYears = (id) => {
      store
        .dispatch(Actions.GET_BUDGET_YEAR_OPTIONS, {business_district_id: id})
        .then(() => {
          tmpFormData.value.budget_year = ''
          tmpFormData.value.budget_quarter = ''
          budget_years.value = store.getters.currentBudgetYearOptions
          budget_quarters.value = []
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const onSelectBudgetYear = () => {
      queryBudgetQuarters()
    }

    const queryBudgetQuarters = () => {
      tmpFormData.value.budget_quarter = ''
      
      budget_quarters.value = [
        {
          key: 1,
          value: 1,
        },
        {
          key: 2,
          value: 2,
        },
        {
          key: 3,
          value: 3,
        },
        {
          key: 4,
          value: 4,
        },
      ]
    }

    return {
      tableData,
      tmpFormData,
      submitEvent,
      business_districts,
      budget_years,
      budget_quarters,
      onCancel,
      onSelectBusinessDistrict,
      onSelectBudgetYear
    }
  },
})
