import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BudgetQuarterListComponent = _resolveComponent("BudgetQuarterListComponent")!
  const _component_CouponDeliverEventListComponent = _resolveComponent("CouponDeliverEventListComponent")!
  const _component_CouponDeliverEventItemListComponent = _resolveComponent("CouponDeliverEventItemListComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BudgetQuarterListComponent, {
          formData: _ctx.formData,
          query: _ctx.query,
          budget_quarter: _ctx.budget_quarter,
          refresh_coupon_deliver_events: _ctx.refresh_coupon_deliver_events
        }, null, 8, ["formData", "query", "budget_quarter", "refresh_coupon_deliver_events"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_CouponDeliverEventListComponent, {
          budget_quarter: _ctx.budget_quarter,
          coupon_deliver_events: _ctx.coupon_deliver_events,
          refresh_coupon_deliver_events: _ctx.refresh_coupon_deliver_events,
          get_coupon_deliver_event_items: _ctx.get_coupon_deliver_event_items
        }, null, 8, ["budget_quarter", "coupon_deliver_events", "refresh_coupon_deliver_events", "get_coupon_deliver_event_items"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_CouponDeliverEventItemListComponent, {
          budget_quarter: _ctx.budget_quarter,
          coupon_deliver_event: _ctx.coupon_deliver_event,
          coupon_deliver_event_items: _ctx.coupon_deliver_event_items,
          reset_coupon_deliver_event_items: _ctx.reset_coupon_deliver_event_items,
          query: _ctx.query,
          add_coupon_deliver_event_item: _ctx.add_coupon_deliver_event_item
        }, null, 8, ["budget_quarter", "coupon_deliver_event", "coupon_deliver_event_items", "reset_coupon_deliver_event_items", "query", "add_coupon_deliver_event_item"])
      ])
    ])
  ], 64))
}