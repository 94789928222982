import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface SystemSettingImage {
  id: number,
  image: string,
  image_url: string,
  link: string,
}

export interface SystemSetting {
  id: number,
  title: string,
  key: string,
  value: string,
  system_setting_images_attributes: Array<SystemSettingImage>,
}

export interface SystemSettingInfo {
  system_setting: SystemSetting,
}

@Module
export default class CouponItemModule
  extends VuexModule
  implements SystemSettingInfo
{
  system_setting = {} as SystemSetting

  get currentSystemSetting(): SystemSetting {
    return this.system_setting
  }

  @Mutation
  [Mutations.SET_SYSTEM_SETTING](system_setting) {
    this.system_setting = system_setting
  }

  @Action
  [Actions.GET_SYSTEM_SETTING](key) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/system_settings/${key}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SYSTEM_SETTING, data["system_setting"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_SYSTEM_SETTING](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/system_settings/${params.system_setting.key}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SYSTEM_SETTING,
            data["system_setting"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        });
    })
  }
}
