import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/users",
        name: "users",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/users/index.vue"),
      },
      {
        path: "/shop_banners",
        name: "shop_banners",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/shop_banners/index.vue"),
      },
      {
        path: "/user_banners",
        name: "user_banners",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/user_banners/index.vue"),
      },
      {
        path: "/admins",
        name: "admins",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/admins/index.vue"),
      },
      {
        path: "/admins/new",
        name: "admin_new",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/admins/new.vue"),
      },
      {
        path: "/admins/:id/edit/",
        name: "admin_edit",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/admins/edit.vue"),
      },
      {
        path: "/pending_events",
        name: "pending_events",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/pending_events/index.vue"),
      },
      {
        path: "/pending_events/:id/edit/",
        name: "pending_event_edit",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/pending_events/edit.vue"),
      },
      {
        path: "/events",
        name: "events",
        meta: {
          roles: ['admin', 'editor']
        },
        component: () => import("@/views/apps/events/index.vue"),
      },
      {
        path: "/events/new",
        name: "event_new",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/events/new.vue"),
      },
      {
        path: "/events/:id/edit/",
        name: "event_edit",
        meta: {
          roles: ['admin', 'editor']
        },
        component: () => import("@/views/apps/events/edit.vue"),
      },
      {
        path: "/events/:id/invitation/",
        name: "event_invitation",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/events/invitation.vue"),
      },
      {
        path: "/events/:id/registration/",
        name: "event_registration",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/events/registration.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/orders/index.vue"),
      },
      {
        path: "/orders/:id/edit/",
        name: "order_edit",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/orders/edit.vue"),
      },

      {
        path: "/coupon_deliver_events",
        name: "coupon_deliver_events",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/coupon_deliver_events/index.vue"),
      },
      {
        path: "/coupon_rule",
        name: "coupon_rule",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/coupon_rule/index.vue"),
      },
      {
        path: "/business_districts",
        name: "business_districts",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/business_districts/index.vue"),
      },
      {
        path: "/business_districts/new",
        name: "business_district_new",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/business_districts/new.vue"),
      },
      {
        path: "/business_districts/:id/edit",
        name: "business_district_edit",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/business_districts/edit.vue"),
      },
      {
        path: "/coupon_items",
        name: "coupon_items",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/coupon_items/index.vue"),
      },
      {
        path: "/coupon_items/new",
        name: "coupon_item_new",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/coupon_items/new.vue"),
      },
      {
        path: "/coupon_items/:id/edit",
        name: "coupon_item_edit",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/coupon_items/edit.vue"),
      },
      {
        path: "/budgets",
        name: "budgets",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/budgets/index.vue"),
      },
      {
        path: "/shops",
        name: "shops",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/shops/index.vue"),
      },
      {
        path: "/shops/new",
        name: "shopNew",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/shops/new.vue"),
      },
      {
        path: "/shops/:id/edit",
        name: "shopEdit",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/shops/edit.vue"),
      },
      {
        path: "/news_items",
        name: "news_items",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/news_items/index.vue"),
      },
      {
        path: "/news_items/new",
        name: "news_item_new",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/news_items/new.vue"),
      },
      {
        path: "/news_items/:id/edit",
        name: "news_item_edit",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/apps/news_items/edit.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          roles: ['admin']
        },
        component: () => import("@/views/Dashboard.vue"),
      },
      // {
      //   path: "/builder",
      //   name: "builder",
      //   component: () => import("@/views/Builder.vue"),
      // },
      // {
      //   path: "/crafted/pages/profile",
      //   name: "profile",
      //   component: () => import("@/components/page-layouts/Profile.vue"),
      //   children: [
      //     {
      //       path: "overview",
      //       name: "profile-overview",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Overview.vue"),
      //     },
      //     {
      //       path: "projects",
      //       name: "profile-projects",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Projects.vue"),
      //     },
      //     {
      //       path: "campaigns",
      //       name: "profile-campaigns",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Campaigns.vue"),
      //     },
      //     {
      //       path: "documents",
      //       name: "profile-documents",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Documents.vue"),
      //     },
      //     {
      //       path: "connections",
      //       name: "profile-connections",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Connections.vue"),
      //     },
      //     {
      //       path: "activity",
      //       name: "profile-activity",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Activity.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/crafted/pages/wizards/horizontal",
      //   name: "horizontal-wizard",
      //   component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      // },
      // {
      //   path: "/crafted/pages/wizards/vertical",
      //   name: "vertical-wizard",
      //   component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      // },
      // {
      //   path: "/crafted/account",
      //   name: "account",
      //   component: () => import("@/views/crafted/account/Account.vue"),
      //   children: [
      //     {
      //       path: "overview",
      //       name: "account-overview",
      //       component: () => import("@/views/crafted/account/Overview.vue"),
      //     },
      //     {
      //       path: "settings",
      //       name: "account-settings",
      //       component: () => import("@/views/crafted/account/Settings.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/apps/customers/getting-started",
      //   name: "apps-customers-getting-started",
      //   component: () => import("@/views/apps/customers/GettingStarted.vue"),
      // },
      // {
      //   path: "/apps/customers/customers-listing",
      //   name: "apps-customers-listing",
      //   component: () => import("@/views/apps/customers/CustomersListing.vue"),
      // },
      // {
      //   path: "/apps/customers/customer-details",
      //   name: "apps-customers-details",
      //   component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      // },
      // {
      //   path: "/subscriptions/getting-started",
      //   name: "subscriptions-getting-started",
      //   component: () =>
      //     import("@/views/apps/subscriptions/GettingStarted.vue"),
      // },
      // {
      //   path: "/subscriptions/subscription-list",
      //   name: "subscriptions-subscription-list",
      //   component: () =>
      //     import("@/views/apps/subscriptions/SubscriptionList.vue"),
      // },
      // {
      //   path: "/subscriptions/add-subscription",
      //   name: "subscriptions-add-subscription",
      //   component: () =>
      //     import("@/views/apps/subscriptions/AddSubscription.vue"),
      // },
      // {
      //   path: "/subscriptions/view-subscription",
      //   name: "subscriptions-view-subscription",
      //   component: () =>
      //     import("@/views/apps/subscriptions/ViewSubscription.vue"),
      // },
      // {
      //   path: "/apps/calendar",
      //   name: "apps-calendar",
      //   component: () => import("@/views/apps/Calendar.vue"),
      // },
      // {
      //   path: "/apps/chat/private-chat",
      //   name: "apps-private-chat",
      //   component: () => import("@/views/apps/chat/Chat.vue"),
      // },
      // {
      //   path: "/apps/chat/group-chat",
      //   name: "apps-group-chat",
      //   component: () => import("@/views/apps/chat/Chat.vue"),
      // },
      // {
      //   path: "/apps/chat/drawer-chat",
      //   name: "apps-drawer-chat",
      //   component: () => import("@/views/apps/chat/DrawerChat.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/invite-friends",
      //   name: "modals-general-invite-friends",
      //   component: () =>
      //     import("@/views/crafted/modals/general/InviteFriends.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/view-user",
      //   name: "modals-general-view-user",
      //   component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/upgrade-plan",
      //   name: "modals-general-upgrade-plan",
      //   component: () =>
      //     import("@/views/crafted/modals/general/UpgradePlan.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/share-and-earn",
      //   name: "modals-general-share-and-earn",
      //   component: () =>
      //     import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-target",
      //   name: "modals-forms-new-target",
      //   component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-card",
      //   name: "modals-forms-new-card",
      //   component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-address",
      //   name: "modals-forms-new-address",
      //   component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/create-api-key",
      //   name: "modals-forms-create-api-key",
      //   component: () =>
      //     import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/two-factor-auth",
      //   name: "modals-wizards-two-factor-auth",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-app",
      //   name: "modals-wizards-create-app",
      //   component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-account",
      //   name: "modals-wizards-create-account",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      // },
      // {
      //   path: "/crafted/widgets/lists",
      //   name: "widgets-list",
      //   component: () => import("@/views/crafted/widgets/Lists.vue"),
      // },
      // {
      //   path: "/crafted/widgets/statistics",
      //   name: "widgets-statistics",
      //   component: () => import("@/views/crafted/widgets/Statistics.vue"),
      // },
      // {
      //   path: "/crafted/widgets/charts",
      //   name: "widgets-charts",
      //   component: () => import("@/views/crafted/widgets/Charts.vue"),
      // },
      // {
      //   path: "/crafted/widgets/mixed",
      //   name: "widgets-mixed",
      //   component: () => import("@/views/crafted/widgets/Mixed.vue"),
      // },
      // {
      //   path: "/crafted/widgets/tables",
      //   name: "widgets-tables",
      //   component: () => import("@/views/crafted/widgets/Tables.vue"),
      // },
      // {
      //   path: "/crafted/widgets/feeds",
      //   name: "widgets-feeds",
      //   component: () => import("@/views/crafted/widgets/Feeds.vue"),
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // console.log(to)
  // console.log(to.meta.roles)
  // console.log(store.getters.currentUser)
  // console.log(store.getters.currentUser.role)

  if (to.meta.roles) {
    if (store.getters.currentUser) {
      if((to.meta.roles as Array<string>).includes(store.getters.currentUser.role)) {
        next()
      } else {
        next({ path: "/404" })
      }
    } else {
      next()
    }
  } else {
    next()
  }

  // console.log("router")
  // console.log(store.getters.currentUser)
  // next()

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
