import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface NewsItem {
  id: number;
  title: string;
  subtitle: string;
  image_url: string;
  image: string;
  publish_date: Date;
  link: string;
}

export interface NewsItemInfo {
  news_items: Array<NewsItem>;
  news_item: NewsItem;
}

@Module
export default class NewsItemModule extends VuexModule implements NewsItemInfo {
  news_items = [] as Array<NewsItem>;
  news_item_count = 0;
  news_item = {} as NewsItem;

  get currentNewsItem(): NewsItem {
    return this.news_item;
  }

  get currentNewsItems(): Array<NewsItem> {
    return this.news_items;
  }

  get currentNewsItemCount() {
    return this.news_item_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_NEWS_ITEM](news_item) {
    this.news_item = news_item;
  }

  @Mutation
  [Mutations.SET_NEWS_ITEMS](news_items) {
    this.news_items = news_items;
  }

  @Mutation
  [Mutations.SET_NEWS_ITEM_COUNT](news_item_count) {
    this.news_item_count = news_item_count;
  }

  @Action
  [Actions.GET_NEWS_ITEMS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/news_items", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NEWS_ITEMS, data["news_items"]);
          this.context.commit(Mutations.SET_NEWS_ITEM_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_NEWS_ITEM](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/news_items/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NEWS_ITEM, data["news_item"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_NEWS_ITEM](credentials) {
    ApiService.setHeader();

    const params = {
      news_item: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/news_items`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NEWS_ITEM, data["news_item"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_NEWS_ITEM](credentials) {
    ApiService.setHeader();

    const params = {
      news_item: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/news_items/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NEWS_ITEM, data["news_item"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
