
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter, useRoute } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import AddEventInvitationModal from "@/components/modals/forms/AddEventInvitationModal.vue"

interface EventInvitation {
  id: number,
  name: string,
  mobile: string,
  code: string,
  status: string,
}

interface User {
  id: number,
}

export default defineComponent({
  components: {
    AddEventInvitationModal
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("活动邀请", ['活动管理'])
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tableData = ref([])
    const eventInvitations = ref(Array<EventInvitation>())

    const formData = ref({
      name: "",
      mobile: "",
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 100,
    })

    const query = () => {
      store
        .dispatch(Actions.GET_EVENT_INVITATIONS, {
          event: {
            id: route.params.id
          },
          query: {
            ...formData.value, 
            ...page.value
          }
        })
        .then(() => {
          eventInvitations.value = store.getters.currentEventInvitations
          tableData.value = store.getters.currentEventInvitations;
          page.value.totalResult = store.getters.currentEventInvitationCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query();

    const submitEvent = () => {
      query();
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    store
      .dispatch(Actions.GET_EVENT, route.params.id)
      .then(() => {
        event.value = store.getters.currentEvent;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const onReset = () => {
      formData.value = {
        name: "",
        mobile: "",
      };
    };

    const event = ref({
      id: 0,
      name: "",
      contact_name: "",
      contact_mobile: "",
      sms_mobile: "",
      latitude_and_longitude: "",
      description: "<div></div>",
      address: "",
      open_from: new Date("1970-01-01T" + "00:00:00"),
      open_to: new Date("1970-01-01T" + "00:00:00"),
      cover_image: "",
      event_images_attributes: [] as Array<any>,
      status: "",
      tag_list: [] as Array<any>,
    });

    const users = ref(Array<User>())

    const onAddEventInvitation = (id) => {
      store
        .dispatch(Actions.GET_USERS)
        .then(() => {
          users.value = store.getters.currentUsers
          console.log(store.getters.currentUsers)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onReset,
      event,
      users,
      onAddEventInvitation,
      query,
    }
  },
})
