
import { defineComponent, ref, toRef } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

interface BudgetYear {
  business_district_id: string;
  year: string;
  total_amount: number;
}

export default defineComponent({
  name: "new-coupon-deliver-event-modal",
  components: {},
  props: [
    "coupon_deliver_event",
    "budget_quarter",
    "refresh_coupon_deliver_events",
  ],
  setup(props) {
    const formRef = ref<FormInstance>();
    const store = useStore();
    const newCouponDeliverEventModalRef = ref<null | HTMLElement>(null);

    const day_options = [
      {label: "星期一", value: 1},
      {label: "星期二", value: 2},
      {label: "星期三", value: 3},
      {label: "星期四", value: 4},
      {label: "星期五", value: 5},
      {label: "星期六", value: 6},
      {label: "星期日", value: 0},
    ]

    const time_options = [
      {label: "00:00", value: "00:00"},
      {label: "00:30", value: "00:30"},
      {label: "01:00", value: "01:00"},
      {label: "01:30", value: "01:30"},
      {label: "02:00", value: "02:00"},
      {label: "02:30", value: "02:30"},
      {label: "03:00", value: "03:00"},
      {label: "03:30", value: "03:30"},
      {label: "04:00", value: "04:00"},
      {label: "04:30", value: "04:30"},
      {label: "05:00", value: "05:00"},
      {label: "05:30", value: "05:30"},
      {label: "06:00", value: "06:00"},
      {label: "06:30", value: "06:30"},
      {label: "07:00", value: "07:00"},
      {label: "07:30", value: "07:30"},
      {label: "08:00", value: "08:00"},
      {label: "08:30", value: "08:30"},
      {label: "09:00", value: "09:00"},
      {label: "09:30", value: "09:30"},
      {label: "10:00", value: "10:00"},
      {label: "10:30", value: "10:30"},
      {label: "11:00", value: "11:00"},
      {label: "11:30", value: "11:30"},
      {label: "12:00", value: "12:00"},
      {label: "12:30", value: "12:30"},
      {label: "13:00", value: "13:00"},
      {label: "13:30", value: "13:30"},
      {label: "14:00", value: "14:00"},
      {label: "14:30", value: "14:30"},
      {label: "15:00", value: "15:00"},
      {label: "15:30", value: "15:30"},
      {label: "16:00", value: "16:00"},
      {label: "16:30", value: "16:30"},
      {label: "17:00", value: "17:00"},
      {label: "17:30", value: "17:30"},
      {label: "18:00", value: "18:00"},
      {label: "18:30", value: "18:30"},
      {label: "19:00", value: "19:00"},
      {label: "19:30", value: "19:30"},
      {label: "20:00", value: "20:00"},
      {label: "20:30", value: "20:30"},
      {label: "21:00", value: "21:00"},
      {label: "21:30", value: "21:30"},
      {label: "22:00", value: "22:00"},
      {label: "22:30", value: "22:30"},
      {label: "23:00", value: "23:00"},
      {label: "23:30", value: "23:30"},
    ]

    const budget_year = ref<BudgetYear>({
      business_district_id: "",
      year: "",
      total_amount: 0,
    })

    const tmp_coupon_deliver_event = toRef(props, "coupon_deliver_event")

    const business_districts = ref([]);

    const onSubmit = (formEl: FormInstance | undefined) => {
      let params = {
        coupon_deliver_event: {
          ...tmp_coupon_deliver_event.value,
          budget_quarter_id: props.budget_quarter.id,
        }
      }

      let target_action = props.coupon_deliver_event.id == 0 ? Actions.CREATE_COUPON_DELIVER_EVENT : Actions.UPDATE_COUPON_DELIVER_EVENT
      
      store
        .dispatch(target_action, params)
        .then(() => {
          props.refresh_coupon_deliver_events()
          hideModal(newCouponDeliverEventModalRef.value)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    const onCancel = () => {
      hideModal(newCouponDeliverEventModalRef.value);
    };

    return {
      newCouponDeliverEventModalRef,
      business_districts,
      budget_year,
      onSubmit,
      formRef,
      tmp_coupon_deliver_event,
      day_options,
      time_options,
    };
  },
});
