import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import BusinessDistrictModule from "@/store/modules/BusinessDistrictModule";
import ShopModule from "@/store/modules/ShopModule";
import BudgetModule from "@/store/modules/BudgetModule";
import AdminModule from "@/store/modules/AdminModule";
import UserModule from "@/store/modules/UserModule";
import OrderModule from "@/store/modules/OrderModule";
import RoleModule from "@/store/modules/RoleModule";
import EventModule from "@/store/modules/EventModule";
import CouponModule from "@/store/modules/CouponModule";
import SystemSettingModule from "@/store/modules/SystemSettingModule";
import NewsItemModule from "@/store/modules/NewsItemModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    BusinessDistrictModule,
    ShopModule,
    BudgetModule,
    AdminModule,
    UserModule,
    RoleModule,
    CouponModule,
    OrderModule,
    EventModule,
    SystemSettingModule,
    NewsItemModule
  },
});

export default store;
