
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewBudgetModal from "@/components/modals/forms/NewBudgetModal.vue";
import BudgetBorrowModal from "@/components/modals/forms/BudgetBorrowModal.vue";
import BudgetAddModal from "@/components/modals/forms/BudgetAddModal.vue";
import { hideModal } from "@/core/helpers/dom"

export default defineComponent({
  components: {
    NewBudgetModal,
    BudgetBorrowModal,
    BudgetAddModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("预算设定", ["消费券管理"]);
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      business_district_id: "",
      budget_year: "",
    });

    const shift_by_budget_quarters = ref([]);

    const query = () => {
      store
        .dispatch(Actions.GET_BUDGET_QUARTERS, formData.value)
        .then(() => {
          tableData.value = store.getters.currentBudgetQuarters;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    // query();

    const submitEvent = () => {
      query();
    };

    const business_districts = ref([]);

    const budget_years = ref([]);

    store
      .dispatch(Actions.GET_BUSINESS_DISTRICTS)
      .then(() => {
        business_districts.value = store.getters.currentBusinessDistricts;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const onReset = () => {
      formData.value = {
        business_district_id: "",
        budget_year: "",
      };
    };

    const budget_quarter = ref({});

    const onBudgetBorrowClick = (id) => {
      store
        .dispatch(Actions.GET_BUDGET_QUARTER, id)
        .then(() => {
          shift_by_budget_quarters.value =
            store.getters.currentShiftByBudgetQuarters;
          budget_quarter.value = store.getters.currentBudgetQuarter;
          budget_quarter.value["total_shift_normal_amount"] = 0;
          budget_quarter.value["total_shift_add_amount"] = 0;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    const onBudgetAddClick = (id) => {
      store
        .dispatch(Actions.GET_BUDGET_QUARTER, id)
        .then(() => {
          budget_quarter.value = store.getters.currentBudgetQuarter;
          budget_quarter.value["new_add_amount"] = 0;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const onSelectBusinessDistrict = (id) => {
      queryBudgetYears(id)
    }

    const queryBudgetYears = (id) => {
      store
        .dispatch(Actions.GET_BUDGET_YEAR_OPTIONS, {business_district_id: id})
        .then(() => {
          formData.value.budget_year = ''
          budget_years.value = store.getters.currentBudgetYearOptions;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const new_budget_submit = (budget_year, newBudgetModalRef) => {
      store
        .dispatch(Actions.CREATE_BUDGET_YEAR, budget_year.value)
        .then(() => {
          queryBudgetYears(formData.value.business_district_id)
          hideModal(newBudgetModalRef.value)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      tableData,
      formData,
      submitEvent,
      business_districts,
      budget_years,
      onReset,
      onBudgetBorrowClick,
      onBudgetAddClick,
      shift_by_budget_quarters,
      budget_quarter,
      query,
      onSelectBusinessDistrict,
      new_budget_submit
    }
  },
});
