
import { defineComponent, ref, toRef } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  name: "shop-login-update-modal",
  props: ["current_shop_id", "shop_login"],
  components: {},
  setup(props) {
    const formRef = ref<FormInstance>();
    const store = useStore();
    const shopLoginModalRef = ref<null | HTMLElement>(null);
    const tmp_shop_login = toRef(props, "shop_login");
    const current_shop_id = toRef(props, "current_shop_id")

    store
      .dispatch(Actions.GET_SHOP_LOGIN)
      .then(() => {
        tmp_shop_login.value = store.getters.currentShopLogin;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return

      formEl.validate((valid) => {
        if (valid) {
          let params = {
            shop: {
              id: current_shop_id.value,
              ...tmp_shop_login.value,
            },
          }

          store
            .dispatch(Actions.UPDATE_SHOP_LOGIN, params)
            .then(() => {
              hideModal(shopLoginModalRef.value)
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        }
      })
    }

    const onCancel = () => {
      hideModal(shopLoginModalRef.value);
    }

    const rules = ref({
      mobile: [
        {
          required: true,
          message: "管理员手机号码不可为空",
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: "密码不可为空",
          trigger: "change",
        },
        {
          min: 6,
          message: "密码长度最少6位",
        },
      ],
      password_confirmation: [
        {
          required: true,
          message: "确认密码不可为空",
          trigger: "change",
        },
        {
          min: 6,
          message: "确认密码长度最少6位",
        },
      ],
    })
    

    return {
      shopLoginModalRef,
      tmp_shop_login,
      onSubmit,
      onCancel,
      formRef,
      rules,
    };
  },
});
