
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ShopSelectModal from "@/components/modals/forms/ShopSelectModal.vue"

interface CouponItem {
  id: number,
  shop_ids: Array<number>,
}

interface Shop {
  id: number,
  selected: boolean,
}

export default defineComponent({
  components: {
    ShopSelectModal
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("票种配置", ["消费券管理"]);
    })

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      name: "",
      contact_mobile: "",
      enabled: "",
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_COUPON_ITEMS, {
          ...formData.value,
          ...page.value,
        })
        .then(() => {
          tableData.value = store.getters.currentCouponItems;
          page.value.totalResult = store.getters.currentCouponItemCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    const submitEvent = () => {
      query()
    };

    const newCouponItem = () => {
      router.push({ name: "coupon_item_new" });
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.name = ''
    }

    const onDelete = (id) => {
      console.log(id)

      store
        .dispatch(Actions.DELETE_COUPON_ITEM, id)
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const onEdit = (id) => {
      router.push({ name: "coupon_item_edit", params: { id: id }})
    }

    const shops = ref(Array<Shop>())

    const onShopSelect = (id) => {
      store
        .dispatch(Actions.GET_COUPON_ITEM, id)
        .then(() => {
          coupon_item.value = store.getters.currentCouponItem

          store
            .dispatch(Actions.GET_SHOPS)
            .then(() => {
              shops.value = store.getters.currentShops
              shops.value.forEach(element => {
                element.selected = coupon_item.value.shop_ids.includes(element.id)
              })
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const coupon_item = ref<CouponItem>({
      id: 0,
      shop_ids: []
    })

    return {
      tableData,
      formData,
      submitEvent,
      newCouponItem,
      page,
      onPageChange,
      onCancel,
      onDelete,
      onEdit,
      onShopSelect,
      coupon_item,
      shops,
    };
  },
});
