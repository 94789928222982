
import { defineComponent } from "vue";
import BusinessDistrictForm from "./Form.vue";

export default defineComponent({
  components: {
    BusinessDistrictForm,
  },
  setup() {
    return {};
  },
});
