
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("用户管理", ["系统管理"]);
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      name: "",
      mobile: "",
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_USERS, { ...formData.value, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentUsers;
          page.value.totalResult = store.getters.currentUserCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const onReset = () => {
      formData.value = {
        name: "",
        mobile: "",
      };
    };

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            minute = '' + d.getMinutes(),
            second = '' + d.getSeconds()

        if (month.length < 2) 
            month = '0' + month
        if (day.length < 2) 
            day = '0' + day
        if (hour.length < 2) 
            hour = '0' + hour
        if (minute.length < 2) 
            minute = '0' + minute
        if (second.length < 2) 
            second = '0' + second

        return [year, month, day, hour, minute, second].join('')
    }

    const onExport = () => {
      store
        .dispatch(Actions.GET_EXPORT_USERS, {
          ...formData.value,
          ...page.value
        })
        .then(() => {
          const blob = new Blob([store.getters.currentExportUsers], { type: "application/xlsx" });
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = `users_${formatDate(Date().toString())}.xlsx`
          link.click();
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onReset,
      onExport
    };
  },
});
