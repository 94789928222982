
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("订单编辑", ["订单管理"]);
      } else {
        setCurrentPageBreadcrumbs("订单编辑", ["订单管理"]);
      }
    })

    const formRef = ref<FormInstance>()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const order = ref({})

    const onCancel = () => {
      router.push({ name: "orders" })
    }

    store
      .dispatch(Actions.GET_ORDER, route.params.id)
      .then(() => {
        order.value = store.getters.currentOrder;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    return {
      order,
      onCancel
    };
  },
});
