
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

interface BudgetYear {
  business_district_id: string;
  year: string;
  total_amount: number;
}

export default defineComponent({
  name: "new-budget-modal",
  components: {},
  props: [
    'new_budget_submit'
  ],
  setup(props) {
    const formRef = ref<FormInstance>();
    const store = useStore();
    const newBudgetModalRef = ref<null | HTMLElement>(null);

    const budget_year = ref<BudgetYear>({
      business_district_id: "",
      year: "",
      total_amount: 0,
    });

    const business_districts = ref([]);

    store
      .dispatch(Actions.GET_BUSINESS_DISTRICTS)
      .then(() => {
        business_districts.value = store.getters.currentBusinessDistricts;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const onSubmit = (formEl: FormInstance | undefined) => {
      props.new_budget_submit(budget_year, newBudgetModalRef)
    }

    const onCancel = () => {
      hideModal(newBudgetModalRef.value);
    };

    return {
      newBudgetModalRef,
      business_districts,
      budget_year,
      onSubmit,
      onCancel,
      formRef,
    };
  },
});
