enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_SHOPS = "getShops",
  GET_SHOP = "getShop",
  CREATE_SHOP = "createShop",
  UPDATE_SHOP = "updateShop",
  GET_BUDGET_YEARS = "getBudgetYears",
  GET_BUDGET_YEAR = "getBudgetYear",
  GET_BUDGET_YEAR_OPTIONS = "getBudgetYearOptions",
  CREATE_BUDGET_YEAR = "createBudgetYear",
  UPDATE_BUDGET_YEAR = "updateBudgetYear",
  GET_BUDGET_QUARTERS = "getBudgetQuarters",
  GET_BUDGET_QUARTER = "getBudgetQuarter",
  GET_BUDGET_QUARTER_BY_PARAMS = "getBudgetQuarterByParams",
  GET_BUSINESS_DISTRICTS = "getBusinessDistricts",
  GET_BUSINESS_DISTRICT = "getDBusinessistrict",
  CREATE_BUSINESS_DISTRICT = "createBusinessDistrict",
  UPDATE_BUSINESS_DISTRICT = "updateBusinessDistrict",
  GET_ADMINS = "getAdmins",
  GET_ADMIN = "getAdmin",
  CREATE_ADMIN = "createAdmin",
  UPDATE_ADMIN = "updateAdmin",
  SHIFT_BUDGET = "shiftBudget",
  ADD_BUDGET = "addBudget",
  GET_COUPON_ITEMS = "getCouponItems",
  GET_COUPON_ITEM = "getCouponItem",
  CREATE_COUPON_ITEM = "createCouponItem",
  UPDATE_COUPON_ITEM = "updateCouponItem",
  UPDATE_COUPON_ITEM_SHOPS = "updateCouponItemShops",
  DELETE_COUPON_ITEM = "deleteCouponITtem",
  GET_COUPON_DELIVER_EVENTS = "getConponDeliverEvents",
  GET_COUPON_DELIVER_EVENT = "getConponDeliverEvent",
  CREATE_COUPON_DELIVER_EVENT = "createConponDeliverEvent",
  UPDATE_COUPON_DELIVER_EVENT = "updateConponDeliverEvent",
  GET_COUPON_SETTINGS = "getCouponSettings",
  UPDATE_COUPON_SETTINGS = "updateCouponSettings",
  GET_COUPON_DELIVER_EVENT_ITEMS = "getCouponDeliverEventItems",
  UPDATE_COUPON_DELIVER_EVENT_ITEMS = "updateCouponDeliverEventItems",
  GET_USERS = "getUsers",
  GET_ORDERS = "getOrders",
  GET_ORDER = "getOrder",
  GET_EXPORT_ORDERS = "getExportOrders",
  GET_EXPORT_USERS = "getExportUses",
  GET_ROLES = "getRoles",
  GET_EVENTS = "getEvents",
  GET_EVENT = "getEvent",
  CREATE_EVENT = "createEvent",
  UPDATE_EVENT = "updateEvent",
  UPDATE_SHOP_LOGIN = "updateShopLogin",
  GET_SHOP_LOGIN = "getShopLogin",
  UPDATE_EVENT_ADMINS = "updateEventAdmins",
  SUBMIT_EVENT = "submitEvent",
  SUSPEND_EVENT = "suspend_event",
  APPROVE_EVENT = "approveEvent",
  UPDATE_EVENT_INVITATIONS = "update_event_invitations",
  GET_EVENT_INVITATIONS = "get_event_invitations",
  GET_PENDING_EVENTS = "get_pending_events",
  REQUIRE_EVENT_APPROVE = "require_event_approve",
  REQUIRE_EVENT_DENY = "require_event_deny",
  GET_SHOP_BANNERS = "get_shop_banners",
  GET_USER_BANNERS = "get_user_banners",
  UPDATE_SYSTEM_SETTING = "update_system_setting",
  GET_SYSTEM_SETTING = "get_system_setting",
  UPDATE_EVENT_GIFT_COUPONS = "update_event_gift_coupon",
  GET_GIFT_COUPONS = "get_gift_coupons",
  GET_EVENT_REGISTRATIONS = "get_event_registrations",
  GET_NEWS_ITEMS = "getNewsItems",
  GET_NEWS_ITEM = "getNewsItem",
  CREATE_NEWS_ITEM = "createNewsItem",
  UPDATE_NEWS_ITEM = "updateNewsItem",
  ENABLE_COUPON_DELIVER_EVENT = "enable_coupon_deliver_event",
  DISABLE_COUPON_DELIVER_EVENT = "disable_coupon_deliver_event"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_SHOPS = "setShops",
  SET_SHOP_COUNT = "setShopCount",
  SET_SHOP = "setShop",
  SET_BUSINESS_DISTRICTS = "setBusinessDistricts",
  SET_BUSINESS_DISTRICT_COUNT = "setBusinessDistrictCount",
  SET_BUSINESS_DISTRICT = "setBusinessDistrict",
  SET_ADMINS = "setAdmins",
  SET_ADMIN_COUNT = "setAdminCount",
  SET_ADMIN = "setAdmin",
  SET_ORDERS = "setOrders",
  SET_ORDER_COUNT = "setOrderCount",
  SET_ORDER = "setOrder",
  SET_EXPORT_ORDERS = "setExportOrders",
  SET_EXPORT_USERS = "setExportUsers",
  SET_USERS = "setUsers",
  SET_USER_COUNT = "setUserCount",
  SET_BUDGET_YEARS = "setBudgetYears",
  SET_BUDGET_YEAR_OPTIONS = "setBudgetYearOptions",
  SET_BUDGET_YEAR = "setBudgetYear",
  SET_BUDGET_QUARTERS = "setBudgetQuarters",
  SET_BUDGET_QUARTER = "setBudgetQuarter",
  SET_SHIFT_BY_BUDGET_QUARTERS = "setShiftByBudgetQuarters",
  SET_COUPON_ITEMS = "setCouponItems",
  SET_COUPON_ITEM_COUNT = "setCouponItemCount",
  SET_COUPON_ITEM = "setCouponItem",
  SET_COUPON_DELIVER_EVENTS = "setCouponDeliverEvents",
  SET_COUPON_DELIVER_EVENT = "setCouponDeliverEvent",
  SET_COUPON_SETTINGS = "setCouponSettings",
  SET_COUPON_DELIVER_EVENT_ITEMS = "setCouponDeliverEventItems",
  SET_EVENTS = "setEvents",
  SET_EVENT_COUNT = "setEventCount",
  SET_EVENT = "setEvent",
  SET_ROLES = "getRoles",
  SET_ROLE_COUNT = "setRoleCount",
  SET_SHOP_LOGIN = "setShopLogin",
  SET_EVENT_INVITATIONS = "setEventInvitations",
  SET_SYSTEM_SETTING = "set_system_setting",
  SET_GIFT_COUPONS = "set_gift_coupons",
  SET_EVENT_INVITATION_COUNT = "set_event_invitation_count",
  SET_EVENT_REGISTRATIONS = "set_event_registrations",
  SET_EVENT_REGISTRATION_COUNT = "set_event_registration_count",
  SET_NEWS_ITEMS = "setNewsItems",
  SET_NEWS_ITEM_COUNT = "setNewsItemCount",
  SET_NEWS_ITEM = "setNewsItem"
}

export { Actions, Mutations };
