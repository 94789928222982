
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("领用规则", ["消费券管理"])
    })

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    })

    const formRef = ref<FormInstance>();
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return

      let params = {
        coupon_settings: {
          ...coupon_settings.value
        },
      };

      formEl.validate((valid) => {
        if (valid) {
          store
            .dispatch(
              Actions.UPDATE_COUPON_SETTINGS,
              params
            )
            .then(() => {
              // router.push({ name: "business_districts" });

              Swal.fire({
                text: "保存成功",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "确认",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary"
                }
              })
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            });
        } else {
          return false
        }
      });
    };

    const coupon_settings = ref({} as any)

    store
      .dispatch(
        Actions.GET_COUPON_SETTINGS
      )
      .then(() => {
        coupon_settings.value = store.getters.currentCouponSettings
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const onCouponUsageAmountInput = (value) => {
      if (value > coupon_settings.value.coupon_taken_limit) {
        coupon_settings.value.coupon_usage_amount = coupon_settings.value.coupon_taken_limit
      }
    }

    const onCouponTakenLimitInput = (value) => {
      if (value < coupon_settings.value.coupon_usage_amount) {
        coupon_settings.value.coupon_usage_amount = coupon_settings.value.coupon_taken_limit
      }
    }

    return {
      onSubmit,
      rules,
      formRef,
      coupon_settings,
      onCouponUsageAmountInput,
      onCouponTakenLimitInput
    };
  },
});
