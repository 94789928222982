
import { defineComponent, ref, toRef } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  name: "admin-select-modal",
  props: ["event", "admins"],
  components: {},
  setup(props) {
    const formRef = ref<FormInstance>();
    const store = useStore();
    const adminSelectModalRef = ref<null | HTMLElement>(null);

    const onSubmit = (formEl: FormInstance | undefined) => {
      let selected_admin_ids = Array<number>()

      props.admins.forEach(element => {
        if (element.selected) {
          selected_admin_ids.push(element.id)
        }
      })

      let params = {
        event: {
          id: props.event.id,
          admin_ids: selected_admin_ids,
        },
      };

      store
        .dispatch(Actions.UPDATE_EVENT_ADMINS, params)
        .then(() => {
          hideModal(adminSelectModalRef.value)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    };

    const onCancel = () => {
      hideModal(adminSelectModalRef.value);
    };

    return {
      onSubmit,
      onCancel,
      formRef,
      adminSelectModalRef,
    };
  },
});
