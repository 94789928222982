
import { defineComponent } from "vue";
import ShopForm from "./Form.vue";

export default defineComponent({
  components: {
    ShopForm,
  },
  setup() {
    return {};
  },
});
