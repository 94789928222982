
import { defineComponent, ref, onMounted, toRef } from "vue";
import { Actions } from "@/store/enums/StoreEnums"; 
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { CouponDeliverEventItem, CouponItem } from "@/store/modules/CouponModule";
import NewCouponDeliverEventItemModal from "@/components/modals/forms/NewCouponDeliverEventItemModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  components: {
    NewCouponDeliverEventItemModal,
  },
  props: [
    "budget_quarter",
    "coupon_deliver_event",
    "coupon_deliver_event_items",
    "reset_coupon_deliver_event_items",
    "query",
    "add_coupon_deliver_event_item"
  ],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const coupon_deliver_event_items = toRef(props, "coupon_deliver_event_items")
    // const tmp_coupon_deliver_event_items = ref(Array<CouponDeliverEventItem>())
    
    const coupon_deliver_event = toRef(props, "coupon_deliver_event")

    const couponDeliverEvent = ref({})

    const coupon_items = ref(Array<CouponItem>())

    const get_coupon_items = () => {
      store
        .dispatch(
          Actions.GET_COUPON_ITEMS
        )
        .then(() => {
          coupon_items.value = store.getters.currentCouponItems
          console.log(coupon_items.value)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const save = () => {
      let total_amount = 0
      
      coupon_deliver_event_items.value.forEach((item) => {
        total_amount += item.total_amount
      })

      console.log("----- coupon_deliver_event.value.locked_amount: " + coupon_deliver_event.value.locked_amount)

      if (total_amount > (props.budget_quarter.total_amount_left + coupon_deliver_event.value.locked_amount)) {
        Swal.fire({
          text: '超过预算总金额',
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "再次确认",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        })

        return
      }

      let params = {
        coupon_deliver_event: {
          budget_quarter_id: coupon_deliver_event.value.budget_quarter_id,
          id: coupon_deliver_event.value.id,
          coupon_deliver_event_items_attributes: coupon_deliver_event_items.value,
        }
      }

      store
        .dispatch(
          Actions.UPDATE_COUPON_DELIVER_EVENT_ITEMS,
          params
        )
        .then(() => {
          props.query(false)

          Swal.fire({
              text: "保存成功",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "确认",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const onChange = () => {
      let date_from = new Date(coupon_deliver_event.value.deliver_from) as Date
      let date_to = new Date(coupon_deliver_event.value.deliver_to) as Date
      let deliver_count = 0

      console.log('date_from: ' + date_from)
      console.log('date_to: ' + date_to)
      console.log('date_from < date_to: ' + (date_from <= date_to))

      while (date_from <= date_to) {
        console.log('coupon_deliver_event.value.deliver_days: ' + coupon_deliver_event.value.deliver_days)
        console.log('date_from.getDay(): ' + date_from.getDay())

        if (coupon_deliver_event.value.deliver_days.includes(date_from.getDay())) {
          deliver_count += coupon_deliver_event.value.deliver_times.length
        }

        date_from = new Date(date_from.getTime() + 24 * 60 * 60 * 1000 * 1) as Date
        console.log('date_from: ' + date_from)
      }

      // let time = date_to - date_from
      // let days = (time / (1000 * 3600 * 24)) + 1
      // let weeks = Math.floor(days / 7)
      // console.log(`---- weeks: ${weeks}`)

      // let day_count = 0
      // let from_day = date_from.getDay()
      // let to_day = date_to.getDay()
      // console.log(`---- from_day: ${from_day}`)
      // console.log(`---- to_day: ${to_day}`)

      // if ((days % 7) != 0) {
      //   if (from_day > 0) {
      //     for (let i = from_day; i <= 6; i++) {
      //       if (coupon_deliver_event.value.deliver_days.includes(i)) {
      //         day_count += 1
      //       }
      //     }
      //   }

      //   if (to_day < 6) {
      //     for (let i = 0; i <= to_day; i++) {
      //       if (coupon_deliver_event.value.deliver_days.includes(i)) {
      //         day_count += 1
      //       }
      //     }
      //   }
      // }

      // if (weeks >= 1) {
      //   day_count += (weeks - 1) * coupon_deliver_event.value.deliver_days.length
      // }
      
      // console.log(`---- day_count: ${day_count}`)
      // console.log(`---- coupon_deliver_event.value.deliver_times.length: ${coupon_deliver_event.value.deliver_times.length}`)
      // let deliver_count = day_count * coupon_deliver_event.value.deliver_times.length

      coupon_deliver_event_items.value.forEach((element) => {
        if (element.coupon_type == 'gift') {
          element.total_count = 1
        } else {
          element.total_count = deliver_count
        }

        console.log('element.total_count: ' + element.total_count)
        console.log('element.deliver_count: ' + element.deliver_count)

        element.left_count= element.total_count - element.deliver_count
        element.total_amount = element.amount * element.single_quantity * element.total_count
        element.total_quantity = element.single_quantity * element.total_count
      })
    }

    return {
      tableData,
      coupon_items,
      couponDeliverEvent,
      get_coupon_items,
      save,
      onChange,
    }
  },
})
