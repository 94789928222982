
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Plus, Delete, ZoomIn } from "@element-plus/icons-vue";
import type { ElUpload } from "element-plus";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    Plus,
    Delete,
    ZoomIn
  },
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("伙伴新增", ["伙伴列表"]);
      } else {
        setCurrentPageBreadcrumbs("伙伴编辑", ["伙伴列表"]);
      }
    });

    const rules = ref({
      business_district_id: [
        {
          required: true,
          message: "所属商圈不可为空",
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: "伙伴名称不可为空",
          trigger: "change",
        },
      ],
      data_type: [
        {
          required: true,
          message: "伙伴类型不可为空",
          trigger: "change",
        },
      ],
      contact_mobile: [
        {
          required: true,
          message: "联系人电话不可为空",
          trigger: "change",
        },
      ],
      contact_name: [
        {
          required: true,
          message: "联系人不可为空",
          trigger: "change",
        },
      ],
      // email: [
      //   {
      //     required: true,
      //     message: "Customer email is required",
      //     trigger: "change",
      //   },
      // ],
      // addressLine: [
      //   {
      //     required: true,
      //     message: "Address 1 is required",
      //     trigger: "change",
      //   },
      // ],
      // town: [
      //   {
      //     required: true,
      //     message: "Town is required",
      //     trigger: "change",
      //   },
      // ],
      // state: [
      //   {
      //     required: true,
      //     message: "State is required",
      //     trigger: "change",
      //   },
      // ],
      // postCode: [
      //   {
      //     required: true,
      //     message: "Post code is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const shop = ref({
      name: "",
      contact_name: "",
      contact_mobile: "",
      sms_mobile: "",
      latitude_and_longitude: "",
      description: "",
      address: "",
      open_from: new Date("1970-01-01T" + "00:00:00"),
      open_to: new Date("1970-01-01T" + "00:00:00"),
      logo_image_url: "",
      cover_image_url: "",
      id_image_url: "",
      logo_image: "",
      cover_image: "",
      id_image: "",
      shop_images_attributes: [] as Array<any>,
      status: "",
      coupon_limit: 0,
    });

    const logoImageUrl = ref("")
    const imageUrl = ref("")
    const idImageUrl = ref("")

    const onChange = (file, fileList) => {
      var reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = (e) => {
        imageUrl.value = e.target?.result?.toString() || ""
        shop.value.cover_image = imageUrl.value
      };
    };

    const onIdImageChange = (file, fileList) => {
      var reader = new FileReader()
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        idImageUrl.value = e.target?.result?.toString() || ""
        shop.value.id_image = idImageUrl.value;
      };
    };

    const onLogoImageChange = (file, fileList) => {
      var reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = (e) => {
        logoImageUrl.value = e.target?.result?.toString() || ""
        shop.value.logo_image = logoImageUrl.value;
      };
    };

    const submitFileList = [] as any;

    const imageListOnChange = (file, fileList) => {
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        submitFileList.push({
          image: e.target?.result,
        });
      };
    };

    const onCancel = () => {
      router.push({ name: "shops" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          shop.value.shop_images_attributes = submitFileList;

          if (isNew) {
            store
              .dispatch(Actions.CREATE_SHOP, shop.value)
              .then(() => {
                router.push({ name: "shops" })
              })
              .catch(() => {
                Swal.fire({
                  text: store.getters.getErrors,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "确认",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                })
              })
          } else {
            store
              .dispatch(Actions.UPDATE_SHOP, {
                id: route.params.id,
                values: shop.value,
              })
              .then(() => {
                router.push({ name: "shops" });
              })
              .catch(() => {
                Swal.fire({
                  text: store.getters.getErrors,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "确认",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                })
              })
          }
        } else {
          console.log("error submit!")
          return false
        }
      })
    }

    const uploadRef = ref<InstanceType<typeof ElUpload>>()

    if (!isNew) {
      store
        .dispatch(Actions.GET_SHOP, route.params.id)
        .then(() => {
          shop.value = store.getters.currentShop;
          shop.value.open_from = new Date(
            "1970-01-01T" + (store.getters.currentShop.open_from || "00:00:00")
          );
          shop.value.open_to = new Date(
            "1970-01-01T" + (store.getters.currentShop.open_to || "00:00:00")
          );

          logoImageUrl.value = shop.value.logo_image_url.startsWith("http")
            ? shop.value.logo_image_url
            : "";

          imageUrl.value = shop.value.cover_image_url.startsWith("http")
            ? shop.value.cover_image_url
            : "";

          idImageUrl.value = shop.value.id_image_url.startsWith("http")
            ? shop.value.id_image_url
            : ""

          shop.value.shop_images_attributes.forEach(function (i) {
            fileList.value.push({
              id: i.id,
              url: i.image.url,
            });
          });
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const disabled = ref(false);

    const handleRemove = (file) => {
      if (file.id) {
        submitFileList.push({
          id: file.id,
          _destroy: 1,
        });
      }

      let resultArr = fileList.value.filter((i) => {
        return i.id === file.id && i.url === file.url;
      });

      fileList.value.splice(fileList.value.indexOf(resultArr[0]), 1);
    };

    const currentImageUrl = ref('')

    const handleZoomIn = (file) => {
      console.log(file.url)
      currentImageUrl.value = file.url
      dialogImageVisible.value = true
    }

    const fileList = ref([] as any);

    const business_districts = ref([]);

    const data_types = ref([
      { name: "商家", id: 'shop' },
      { name: "企业", id: 'business_unit' },
    ])
    
    store
      .dispatch(Actions.GET_BUSINESS_DISTRICTS)
      .then(() => {
        business_districts.value = store.getters.currentBusinessDistricts;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const dialogImageVisible = ref(false)

    return {
      onSubmit,
      shop,
      logoImageUrl,
      imageUrl,
      idImageUrl,
      rules,
      uploadRef,
      onLogoImageChange,
      onChange,
      onIdImageChange,
      handleRemove,
      dialogImageUrl,
      dialogVisible,
      disabled,
      fileList,
      imageListOnChange,
      onCancel,
      formRef,
      business_districts,
      data_types,
      handleZoomIn,
      dialogImageVisible,
      currentImageUrl
    };
  },
});
