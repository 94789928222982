import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface User {
  name: string,
  mobile: string,
  created_at: string,
}

@Module
export default class UserModule extends VuexModule {
  users = [] as Array<User>
  user_count = 0
  export_users = {} as any

  get currentUsers(): Array<User> {
    return this.users;
  }

  get currentUserCount() {
    return this.user_count;
  }

  get currentExportUsers() {
    return this.export_users
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_USER_COUNT](user_count) {
    this.user_count = user_count;
  }

  @Mutation
  [Mutations.SET_EXPORT_USERS](export_users) {
    this.export_users = export_users;
  }

  @Action
  [Actions.GET_USERS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/users", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USERS, data["users"]);
          this.context.commit(Mutations.SET_USER_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EXPORT_USERS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/users/export", queryString, {responseType: 'blob'})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EXPORT_USERS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
