
import { defineComponent } from "vue";
import CouponItemForm from "./Form.vue";

export default defineComponent({
  components: {
    CouponItemForm,
  },
  setup() {
    return {};
  },
});
