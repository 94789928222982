
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import type { ElUpload } from "element-plus"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import type { ElForm } from "element-plus"
import { NewsItem } from "@/store/modules/NewsItemModule"

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("新闻新增", ["新闻列表"]);
      } else {
        setCurrentPageBreadcrumbs("新闻编辑", ["新闻列表"]);
      }
    });

    const rules = ref({})

    const formRef = ref<FormInstance>()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const news_item = ref<NewsItem>({
      id: 0,
      title: '',
      subtitle: '',
      image_url: '',
      publish_date: new Date("1970-01-01T" + "00:00:00"),
      link: '',
      image: ''
    })

    const imageUrl = ref("")

    const onImageChange = (file, fileList) => {
      var reader = new FileReader()
      reader.readAsDataURL(file.raw)

      reader.onload = (e) => {
        imageUrl.value = e.target?.result?.toString() || ""
        news_item.value.image = imageUrl.value
      }
    }

    const onCancel = () => {
      router.push({ name: "news_items" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          if (isNew) {
            store
              .dispatch(Actions.CREATE_NEWS_ITEM, news_item.value)
              .then(() => {
                router.push({ name: "news_items" })
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_NEWS_ITEM, {
                id: route.params.id,
                values: news_item.value,
              })
              .then(() => {
                router.push({ name: "news_items" })
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              })
          }
        } else {
          console.log("error submit!")
          return false
        }
      })
    }

    const uploadRef = ref<InstanceType<typeof ElUpload>>();

    if (!isNew) {
      store
        .dispatch(Actions.GET_NEWS_ITEM, route.params.id)
        .then(() => {
          news_item.value = store.getters.currentNewsItem

          // news_item.value.publish_date = new Date(
          //   "1970-01-01T" + (store.getters.currentNewsItem.publish_date || "00:00:00")
          // )

          imageUrl.value = news_item.value.image_url.startsWith("http")
            ? news_item.value.image_url
            : ""
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const disabled = ref(false);

    return {
      onSubmit,
      news_item,
      imageUrl,
      rules,
      uploadRef,
      onImageChange,
      dialogImageUrl,
      dialogVisible,
      disabled,
      onCancel,
      formRef
    };
  }
})
