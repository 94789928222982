
import { defineComponent, ref, toRef } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  name: "new-coupon-deliver-event-item-modal",
  components: {},
  props: [
    "add_coupon_deliver_event_item",
    "coupon_items",
  ],
  setup(props) {
    const formRef = ref<FormInstance>();
    const store = useStore();
    const newCouponDeliverEventItemModalRef = ref<null | HTMLElement>(null);

    const onSubmit = (formEl: FormInstance | undefined) => {
      props.add_coupon_deliver_event_item(coupon_item_id.value)
      coupon_item_id.value = ""
      hideModal(newCouponDeliverEventItemModalRef.value)

      // let params = {
      //   coupon_deliver_event: {
      //     ...tmp_coupon_deliver_event.value,
      //     budget_quarter_id: props.budget_quarter.id,
      //   }
      // }

      // let target_action = props.coupon_deliver_event.id == 0 ? Actions.CREATE_COUPON_DELIVER_EVENT : Actions.UPDATE_COUPON_DELIVER_EVENT
      
      // store
      //   .dispatch(target_action, params)
      //   .then(() => {
      //     props.refresh_coupon_deliver_events()
      //     hideModal(newCouponDeliverEventModalRef.value)
      //   })
      //   .catch(() => {
      //     // const [error] = Object.keys(store.getters.getErrors);
      //     // Swal.fire({
      //     //   text: store.getters.getErrors[error],
      //     //   icon: "error",
      //     //   buttonsStyling: false,
      //     //   confirmButtonText: "Try again!",
      //     //   customClass: {
      //     //     confirmButton: "btn fw-bold btn-light-danger",
      //     //   },
      //     // });
      //   });
    };

    const onCancel = () => {
      hideModal(newCouponDeliverEventItemModalRef.value);
    };

    const coupon_item_id = ref("")

    return {
      newCouponDeliverEventItemModalRef,
      onSubmit,
      formRef,
      coupon_item_id,
    };
  },
});
