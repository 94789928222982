
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("新闻列表", []);
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      name: "",
      contact_mobile: "",
      enabled: "",
      business_district_id: "",
      data_type: "",
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 100,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_NEWS_ITEMS, { ...formData.value, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentNewsItems;
          page.value.totalResult = store.getters.currentNewsItemCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    }

    const new_news_item = () => {
      router.push({ name: "news_item_new" });
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    }

    const onEditClick = (id) => {
      router.push({name: "news_item_edit", params: { id: id }})
    }

    return {
      tableData,
      formData,
      submitEvent,
      new_news_item,
      page,
      onPageChange,
      onEditClick,
    };
  },
});
