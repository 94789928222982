import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Admin {
  name: string;
  // surname: string;
  // email: string;
  // password: string;
  // api_token: string;
}

export interface AdminInfo {
  admins: Array<Admin>;
  admin: Admin;
}

@Module
export default class AdminModule extends VuexModule implements AdminInfo {
  admins = [] as Array<Admin>;
  admin_count = 0;
  admin = {} as Admin;

  get currentAdmin(): Admin {
    return this.admin;
  }

  get currentAdmins(): Array<Admin> {
    return this.admins;
  }

  get currentAdminCount() {
    return this.admin_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_ADMIN](admin) {
    this.admin = admin;
  }

  @Mutation
  [Mutations.SET_ADMINS](admins) {
    this.admins = admins;
  }

  @Mutation
  [Mutations.SET_ADMIN_COUNT](admin_count) {
    this.admin_count = admin_count;
  }

  @Action
  [Actions.GET_ADMINS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/admins", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMINS, data["admins"]);
          this.context.commit(Mutations.SET_ADMIN_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ADMIN](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/admins/${id}`)
        .then(({ data }) => {
          console.log(data["admin"]);
          this.context.commit(Mutations.SET_ADMIN, data["admin"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_ADMIN](credentials) {
    ApiService.setHeader();

    const params = {
      admin: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/admins`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN, data["admin"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_ADMIN](credentials) {
    ApiService.setHeader();

    const params = {
      admin: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/admins/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN, data["admin"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
