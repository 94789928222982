
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("管理员列表", ["系统管理"]);
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      name: "",
      mobile: "",
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_ADMINS, { ...formData.value, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentAdmins;
          page.value.totalResult = store.getters.currentAdminCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const newAdmin = () => {
      router.push({ name: "admin_new" });
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const onReset = () => {
      formData.value = {
        name: "",
        mobile: "",
      };
    };

    return {
      tableData,
      formData,
      submitEvent,
      newAdmin,
      page,
      onPageChange,
      onReset,
    };
  },
});
