import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Order {
  no: string,
  shop_contact_mobile: string,
  pay_amount: number,
  origin_amount: number,
  status: string,
  created_at: string,
}

@Module
export default class OrderModule extends VuexModule {
  orders = [] as Array<Order>;
  order_count = 0;
  order = {} as Order;
  export_orders = {} as any

  get currentOrder(): Order {
    return this.order;
  }

  get currentOrders(): Array<Order> {
    return this.orders;
  }

  get currentExportOrders() {
    return this.export_orders
  }

  get currentOrderCount() {
    return this.order_count;
  }

  @Mutation
  [Mutations.SET_ORDER](order) {
    this.order = order;
  }

  @Mutation
  [Mutations.SET_ORDERS](orders) {
    this.orders = orders;
  }

  @Mutation
  [Mutations.SET_EXPORT_ORDERS](export_orders) {
    this.export_orders = export_orders;
  }

  @Mutation
  [Mutations.SET_ORDER_COUNT](order_count) {
    this.order_count = order_count;
  }

  @Action
  [Actions.GET_ORDERS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/orders", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ORDERS, data["orders"]);
          this.context.commit(Mutations.SET_ORDER_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EXPORT_ORDERS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/orders/export", queryString, {responseType: 'blob'})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EXPORT_ORDERS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ORDER](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/orders/${id}`)
        .then(({ data }) => {
          console.log(data["order"]);
          this.context.commit(Mutations.SET_ORDER, data["order"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
