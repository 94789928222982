
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ShopLoginUpdateModal from "@/components/modals/forms/ShopLoginUpdateModal.vue";
import { ShopLogin } from "@/store/modules/ShopModule"

export default defineComponent({
  components: {
    ShopLoginUpdateModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("伙伴列表", []);
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      name: "",
      contact_mobile: "",
      enabled: "",
      business_district_id: "",
      data_type: "",
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 100,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_SHOPS, { ...formData.value, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentShops;
          page.value.totalResult = store.getters.currentShopCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const newShop = () => {
      router.push({ name: "shopNew" });
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const business_districts = ref([
      // {name: '1', id: '1'},
      // {name: '2', id: '2'},
    ]);

    const data_types = ref([
      { name: "商家", id: 0 },
      { name: "企业", id: 1 },
    ]);

    store
      .dispatch(Actions.GET_BUSINESS_DISTRICTS)
      .then(() => {
        business_districts.value = store.getters.currentBusinessDistricts;
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const onReset = () => {
      formData.value = {
        name: "",
        contact_mobile: "",
        enabled: "",
        business_district_id: "",
        data_type: "",
      };
    };

    const shop_login = ref<ShopLogin>({
      mobile: "",
      password: "",
      password_confirmation: "",
    })

    const current_shop_id = ref("")

    const onShopLoginUpdateClick = (id) => {
      console.log("onShopLoginUpdateClick")

      let params = {
        shop: {
          id: id,
        }
      }

      store
        .dispatch(Actions.GET_SHOP_LOGIN, params)
        .then(() => {
          current_shop_id.value = id
          shop_login.value = store.getters.currentShopLogin
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const onEditClick = (id) => {
      router.push({name: "shopEdit", params: { id: id }})
    }

    return {
      tableData,
      formData,
      submitEvent,
      newShop,
      page,
      onPageChange,
      data_types,
      business_districts,
      onReset,
      shop_login,
      onShopLoginUpdateClick,
      current_shop_id,
      onEditClick,
    };
  },
});
