
import { defineComponent, ref, onMounted, toRef } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import NewCouponDeliverEventModal from "@/components/modals/forms/NewCouponDeliverEventModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js"

interface BudgetQuarter {
  id: number,
  no: string,
  total_amount: number,
  normal_amount: number,
  add_amount: number,
}

interface CouponDeliverEvent {
  id: number,
  deliver_from: string,
  deliver_to: string,
  deliver_days: Array<string>,
  deliver_times: Array<string>,
}

export default defineComponent({
  components: {
    NewCouponDeliverEventModal,
  },
  props: [
    "budget_quarter",
    "coupon_deliver_events",
    "refresh_coupon_deliver_events",
    "get_coupon_deliver_event_items"
  ],
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const tableData = ref([])

    const coupon_deliver_events = toRef(props, "coupon_deliver_events")

    const coupon_deliver_event = ref<CouponDeliverEvent>({
      id: 0,
      deliver_from: "",
      deliver_to: "",
      deliver_days: [],
      deliver_times: [],
    })

    const reset_coupon_deliver_event = () => {
      coupon_deliver_event.value = {
        id: 0,
        deliver_from: "",
        deliver_to: "",
        deliver_days: [],
        deliver_times: [],
      }
    }

    const formData = ref({
      name: "",
      contact_mobile: "",
      enabled: "",
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    });

    const budget_quarter = toRef(props, "budget_quarter")

    const enable_coupon_deliver_event = (item) => {
      let params = {
        coupon_deliver_event: {
          budget_quarter_id: budget_quarter.value.id,
          id: item.id,
        }
      }

      store
        .dispatch(
          Actions.ENABLE_COUPON_DELIVER_EVENT, 
          params
        )
        .then(() => {
          item.enabled = true
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const disable_coupon_deliver_event = (item) => {
      let params = {
        coupon_deliver_event: {
          budget_quarter_id: budget_quarter.value.id,
          id: item.id,
        }
      }

      store
        .dispatch(
          Actions.DISABLE_COUPON_DELIVER_EVENT, 
          params
        )
        .then(() => {
          item.enabled = false
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const set_coupon_deliver_event = (id) => {
      let params = {
        coupon_deliver_event: {
          budget_quarter_id: budget_quarter.value.id,
          id: id,
        }
      }
      
      store
        .dispatch(
          Actions.GET_COUPON_DELIVER_EVENT, 
          params
        )
        .then(() => {
          coupon_deliver_event.value = store.getters.currentCouponDeliverEvent
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      tableData,
      formData,
      page,
      coupon_deliver_event,
      reset_coupon_deliver_event,
      set_coupon_deliver_event,
      enable_coupon_deliver_event,
      disable_coupon_deliver_event
    }
  },
})
