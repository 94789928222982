import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Role {
  name: string,
  mobile: string,
  created_at: string,
}

@Module
export default class RoleModule extends VuexModule {
  roles = [] as Array<Role>
  role_count = 0

  get currentRoles(): Array<Role> {
    return this.roles;
  }

  get currentRoleCount() {
    return this.role_count;
  }

  @Action
  [Actions.GET_ROLES](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/roles", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ROLES, data["roles"]);
          this.context.commit(Mutations.SET_ROLE_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
