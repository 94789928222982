
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";
import { SystemSetting, SystemSettingImage } from "@/store/modules/SystemSettingModule"
import { Plus, Delete } from "@element-plus/icons-vue"
import type { ElUpload } from "element-plus"

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    Plus,
    Delete,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("用户端轮播图", ["系统管理"])
    })

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    })

    const formRef = ref<FormInstance>();
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return

      let params = {
        system_setting: {
          ...system_setting.value
        },
      }

      formEl.validate((valid) => {
        if (valid) {
          store
            .dispatch(
              Actions.UPDATE_SYSTEM_SETTING,
              params
            )
            .then(() => {
              // router.push({ name: "business_districts" });
              Swal.fire({
                text: "保存成功",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "确认",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary"
                }
              })
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            });
        } else {
          return false
        }
      })
    }

    const submitFileList = [] as any;
    const fileList = ref([] as any);

    const handleRemove = (item) => {
      item._destroy = 1
    }
    
    const onImageChange = (file, item) => {
      var reader = new FileReader()
      reader.readAsDataURL(file.raw)

      reader.onload = (e) => {
        item.image = e.target?.result
        item.image_url = e.target?.result
      }
    }

    const system_setting = ref<SystemSetting>({
      id: 0,
      title: '',
      key: '',
      value: '',
      system_setting_images_attributes: []
    })

    store
      .dispatch(Actions.GET_SYSTEM_SETTING, 'user_banners')
      .then(() => {
        system_setting.value = store.getters.currentSystemSetting
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const uploadRef = ref<InstanceType<typeof ElUpload>>()

    const onNew = (item) => {
      system_setting.value.system_setting_images_attributes.push({} as SystemSettingImage)
    }

    return {
      uploadRef,
      onSubmit,
      rules,
      formRef,
      onImageChange,
      handleRemove,
      fileList,
      system_setting,
      onNew
    };
  },
});
