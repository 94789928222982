
import { defineComponent, ref, onMounted } from "vue";
import BudgetQuarterListComponent from "../../../components/coupon_deliver_events/BudgetQuarter.vue";
import CouponDeliverEventListComponent from "../../../components/coupon_deliver_events/Index.vue";
import CouponDeliverEventItemListComponent from "../../../components/coupon_deliver_events/CouponDeliverEventItem.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import { CouponDeliverEventItem } from "@/store/modules/CouponModule";
// import { CouponDeliverEvent } from "@/store/modules/CouponModule";

interface BudgetQuarter {
  id: number,
  no: string,
  total_amount: number,
  total_amount_left: number,
  normal_amount: number,
  normal_amount_left: number,
  add_amount: number,
  add_amount_left: number,
  deliver_amount: number,
  taked_amount: number,
  untake_amount: number,
  used_amount: number,
  unuse_amount: number,
  status_display: string,
}

interface CouponDeliverEvent {
  id: number,
  no: string,
  duration: string,
  days: string,
  times: string,
  locked_amount: number,
  used_amount: number,
  status: number,
  enabled: boolean
}

export default defineComponent({
  components: {
    BudgetQuarterListComponent,
    CouponDeliverEventListComponent,
    CouponDeliverEventItemListComponent,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("放券与增发", ["消费券管理"])
    })

    const store = useStore()

    const budget_quarter = ref<BudgetQuarter>({
      id: 0,
      no: "",
      total_amount: 0,
      total_amount_left: 0,
      normal_amount: 0,
      normal_amount_left: 0,
      add_amount: 0,
      add_amount_left: 0,
      deliver_amount: 0,
      taked_amount: 0,
      untake_amount: 0,
      used_amount: 0,
      unuse_amount: 0,
      status_display: ''
    })

    const coupon_deliver_events = ref([] as Array<CouponDeliverEvent>)

    const refresh_coupon_deliver_events = () => {
      store
        .dispatch(Actions.GET_COUPON_DELIVER_EVENTS, budget_quarter.value.id)
        .then(() => {
          coupon_deliver_events.value = store.getters.currentCouponDeliverEvents

          if (coupon_deliver_event.value.id != 0) {
            let new_coupon_deliver_event = coupon_deliver_events.value.filter((item) => {
              return item.id == coupon_deliver_event.value.id
            })[0]

            coupon_deliver_event.value = new_coupon_deliver_event
          }
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const coupon_deliver_event_items = ref(Array<CouponDeliverEventItem>())

    const get_coupon_deliver_event_items = (item) => {
      coupon_deliver_event.value = item

      let params = {
        coupon_deliver_event: {
          budget_quarter_id: budget_quarter.value.id,
          id: item.id,
        }
      }
      
      store
        .dispatch(
          Actions.GET_COUPON_DELIVER_EVENT_ITEMS, 
          params
        )
        .then(() => {
          coupon_deliver_event_items.value = store.getters.currentCouponDeliverEventItems
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const reset_coupon_deliver_event_items = () => {
      get_coupon_deliver_event_items(coupon_deliver_event.value)
    }

    const formData = ref({
      business_district_id: "",
      budget_year: "",
      budget_quarter: "",
    })

    const coupon_deliver_event = ref({} as CouponDeliverEvent)

    const init_coupon_deliver_event = () => {
      coupon_deliver_event.value = {
        id: 0,
        no: "",
        duration: "",
        days: "",
        times: "",
        locked_amount: 0,
        used_amount: 0,
        status: 0,
        enabled: false
      }
    }

    init_coupon_deliver_event()

    const query = (clear_coupon_deliver_items) => {
      if (!(formData.value.business_district_id && formData.value.budget_year && formData.value.budget_quarter))
        return
        
      store
        .dispatch(Actions.GET_BUDGET_QUARTER_BY_PARAMS, {
          budget_quarter: {
            business_district_id: formData.value.business_district_id,
            budget_year: formData.value.budget_year,
            budget_quarter: formData.value.budget_quarter,
          }
        })
        .then(() => {
          budget_quarter.value.id = store.getters.currentBudgetQuarter.id
          budget_quarter.value.no = store.getters.currentBudgetQuarter.no
          budget_quarter.value.total_amount = store.getters.currentBudgetQuarter.total_amount
          budget_quarter.value.normal_amount = store.getters.currentBudgetQuarter.normal_amount
          budget_quarter.value.normal_amount_left = store.getters.currentBudgetQuarter.normal_amount_left
          budget_quarter.value.add_amount = store.getters.currentBudgetQuarter.add_amount
          budget_quarter.value.add_amount_left = store.getters.currentBudgetQuarter.add_amount_left
          budget_quarter.value.status_display = store.getters.currentBudgetQuarter.status_display
          budget_quarter.value.total_amount_left = store.getters.currentBudgetQuarter.total_amount_left
          budget_quarter.value.deliver_amount = store.getters.currentBudgetQuarter.deliver_amount
          budget_quarter.value.taked_amount = store.getters.currentBudgetQuarter.taked_amount
          budget_quarter.value.untake_amount = store.getters.currentBudgetQuarter.untake_amount
          budget_quarter.value.used_amount = store.getters.currentBudgetQuarter.used_amount
          budget_quarter.value.unuse_amount = store.getters.currentBudgetQuarter.unuse_amount

          refresh_coupon_deliver_events()
          
          if (clear_coupon_deliver_items) {
            init_coupon_deliver_event()
            clear_coupon_event_deliver_items()
          }

          // if (coupon_deliver_event) {
          //   get_coupon_deliver_event_items(coupon_deliver_event.value)
          // }
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const clear_coupon_event_deliver_items = () => {
      coupon_deliver_event_items.value = []
    }

    const add_coupon_deliver_event_item = (coupon_item_id) => {
      if (coupon_item_id) {
        store
        .dispatch(
          Actions.GET_COUPON_ITEM, 
          coupon_item_id
        )
        .then(() => {
          let coupon_deliver_event_item = {
            coupon_item_id: store.getters.currentCouponItem.id,
            no: store.getters.currentCouponItem.no,
            name: store.getters.currentCouponItem.name,
            amount: store.getters.currentCouponItem.amount,
            deliver_type: store.getters.currentCouponItem.deliver_type,
            coupon_type: store.getters.currentCouponItem.coupon_type,
            single_quantity: 0,
            total_quantity: 0,
            deliver_count: 0,
            left_count: 0,
            total_count: 0,
            total_amount: 0,
            taked_count: 0,
          } as CouponDeliverEventItem

          coupon_deliver_event_items.value.push(coupon_deliver_event_item)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
      }
    }

    return {
      budget_quarter,
      refresh_coupon_deliver_events,
      coupon_deliver_events,
      coupon_deliver_event,
      get_coupon_deliver_event_items,
      coupon_deliver_event_items,
      reset_coupon_deliver_event_items,
      formData,
      query,
      add_coupon_deliver_event_item
      // setCurrentCouponDeliverEvent
    }
  }
})
